import { reactive, ref } from 'vue';
import { Project } from '../../../project';
import { useToastStore } from '../../../store/toasts';
import {
  ProjectOfflineManagementRequestData,
  getDefaultOfflineRequestData,
} from '../../../offline-data';
import useApi from '../../../api';
import useAuth from '../../../composables/useAuth';

const useManageOfflineData = (
  project: Project,
  finishCallback: Function,
  errorCallback: Function,
  defaultOfflineRequestData: ProjectOfflineManagementRequestData | null = null
) => {
  const auth = useAuth();
  const api = useApi();
  const toastStore = useToastStore();

  const isImporting = ref(false);

  const offlineRequest = reactive<ProjectOfflineManagementRequestData>(
    defaultOfflineRequestData ||
    getDefaultOfflineRequestData(useAuth().user().user_id)
  );

  const startQuickImport = async () => {
    try {
      isImporting.value = true;

      await api.post('/project/offline', {
        import_type: 'quick',
        ...offlineRequest,
        project_id: project.project_id,
      });

      isImporting.value = false;

      finishCallback();
    } catch (e: any) {
      isImporting.value = false;

      toastStore.error('Error using Quick Download, an admin has been notified.');

      errorCallback(e);
    }
  };

  const startAdvancedImport = async () => {
    try {
      isImporting.value = true;

      await api.post('/project/offline', {
        import_type: 'advanced',
        ...offlineRequest,
        project_id: project.project_id,
      });

      isImporting.value = false;

      finishCallback();
    } catch (e) {
      isImporting.value = false;
      toastStore.error(
        'Error importing offline data, an admin has been notified.'
      );

      errorCallback();

      throw e;
    }
  };

  return {
    offlineRequest,
    isImporting,
    startQuickImport,
    startAdvancedImport,
  };
};

export default useManageOfflineData;
