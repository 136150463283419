const getPaginatedSamples = (params) => {
  return axios.get('/api/project/sample/get-paginated', {
    params,
  });
};

const modifySample = (data) => {
  return axios.post('/api/project/sample/update', data);
};

const modifySampleValue = (data) => {
  return axios.post('/api/project/sample/update-value', data, {
    header: { 'Content-Type': 'multipart/form-data' },
  });
};

const modifySampleCoord = (data) => {
  return axios.post('/api/project/sample/set-coordinates', data);
};

const deleteSample = (sample_id) => {
  return axios.delete('/api/project/sample/delete/' + sample_id);
};

const duplicateSample = (data) => {
  return axios.post('api/project/sample/duplicate', data);
};

const copyLinkedApp = (data) => {
  return axios.post('/api/project/sample/copy-linked-app', data);
};

const toggleProjectOffline = () => {
  return axios.post('/api/project/toggle-offline');
};

const syncProjectOffline = (data) => {
  return axios.post('/api/project/sample/sync-offline', data);
};

const uploadInputValueBase64Image = (data) => {
  return axios.post('/api/project/sample/values/image-base64', data);
};

const toggleLayer = (data) => {
  return axios.post('api/project/layer/toggle', data);
};

const joinTeam = (role_id) => {
  return axios.post('api/project/join-team', { role_id });
};

const updateNonSpatialView = (data) => {
  return axios.put('api/project/change-view-type', data);
};

export default {
  getPaginatedSamples,
  modifySample,
  modifySampleValue,
  modifySampleCoord,
  duplicateSample,
  copyLinkedApp,
  deleteSample,
  toggleProjectOffline,
  syncProjectOffline,
  uploadInputValueBase64Image,
  toggleLayer,
  joinTeam,
  updateNonSpatialView,
};
