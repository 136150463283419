import localForage from 'localforage';
import { OfflineProject, OfflineSample } from '../offline-data';

export interface OfflineStorageManagerReturnTypeSW {
  offlineProjects: Ref<OfflineProject[]>;

  loadProjects: () => Promise<void>;
  setProjects: (projects: OfflineProject[]) => Promise<void>;
  getProjectById: (id: number) => OfflineProject | undefined;

  createOfflineProject: (project: OfflineProject) => Promise<void>;
  deleteOfflineProject: (id: number) => Promise<void>;
  updateOfflineProject: (
    id: number,
    data: Partial<OfflineProject>
  ) => Promise<void>;

  createOfflineSample: (
    sample: OfflineSample,
    project_id: number
  ) => Promise<void>;
  updateOfflineSample: (
    data: UpdateSampleRequest,
    project_id: number
  ) => Promise<void>;
  deleteOfflineSample: (
    id: number | string,
    project_id: number
  ) => Promise<void>;
  updateOfflineSampleValues: (
    sample_id: number | string,
    values: any[],
    project_id: number
  ) => Promise<void>;
}

export type UpdateSampleRequest = {
  template_tab_id: number;
  sample_id: number;
  custom_title: string;
  latitude: string;
  longitude: string;
  area_geojson: any;
  input_values: any;
  sub_folder: any;
  is_edited_offline?: boolean;
};

export type OfflineStorageKey = {
  offline_projects: OfflineProject[];
};

const STORAGE_KEY = 'offline_mode_data';

/**
 * Had to extend wrap so that this can be used as a dependency to other composables using either Pinia or LocalForage
 */
interface Ref<T> {
  value: T;
}

function ref<T>(initialValue: T): { value: T } {
  let value = initialValue;

  return {
    get value() {
      return value;
    },
    set value(newValue: T) {
      value = newValue;
    },
  };
}

/**
 * Used to manage localForage data for offline projects
 */
export const useOfflineStorageManagerStoreSW =
  (): OfflineStorageManagerReturnTypeSW => {
    const offlineProjects = ref<OfflineProject[]>([]);

    const loadProjects = async () => {
      const offlineVuex = ((await localForage.getItem(STORAGE_KEY)) || {
        offline_projects: [],
      }) as OfflineStorageKey;

      offlineProjects.value = offlineVuex.offline_projects;
    };

    const setProjects = async (projects: OfflineProject[]) => {
      offlineProjects.value = projects;

      await updateLocalForage();
    };

    const getProjectById = (id: number) => {
      return offlineProjects.value.find((p) => p.project_id == id);
    };

    const createOfflineProject = async (project: OfflineProject) => {
      offlineProjects.value.push(project);

      await updateLocalForage();
    };

    const deleteOfflineProject = async (id: number) => {
      offlineProjects.value.splice(
        offlineProjects.value.findIndex((p) => p.project_id == id),
        1
      );

      await updateLocalForage();
    };

    const updateOfflineProject = async (
      id: number,
      data: Partial<OfflineProject>
    ) => {
      const offlineProject = offlineProjects.value.find(
        (p) => p.project_id == id
      );

      if (!offlineProject) {
        throw Error('Could not find project.');
      }

      Object.keys(data).forEach((key) => {
        offlineProject[key] = data[key];
      });

      await updateLocalForage();
    };

    const createOfflineSample = async (
      sample: OfflineSample,
      project_id: number
    ) => {
      const offlineProject = offlineProjects.value.find(
        (p) => p.project_id == project_id
      );

      if (!offlineProject) {
        throw Error('Could not find project.');
      }

      if (
        sample.id &&
        offlineProject.samples.findIndex((s) => s.id == sample.id) !== -1
      ) {
        console.log('sample already exists');
        return;
      }

      _updateOfflinePolySample(sample, offlineProject);

      offlineProject.samples.push(sample);

      await updateLocalForage();

      console.log(`synced sample id: ${sample.id}`);
    };

    const updateOfflineSample = async (
      data: UpdateSampleRequest,
      project_id: number
    ) => {
      const offlineProject = offlineProjects.value.find(
        (p) => p.project_id == project_id
      );

      if (!offlineProject) {
        throw Error('Could not find project.');
      }

      const supportedAttributes = [
        'custom_title',
        'latitude',
        'longitude',
        'template_tab_id',
        'area_figure_layer',
        'area_geojson',
        'is_edited_offline',
      ];

      const sampleToUpdate = offlineProject.samples.find(
        (s) => s.id == data.sample_id
      );

      if (!sampleToUpdate) {
        throw Error('Could not find sample to update');
      }

      Object.keys(data)
        .filter((key) => supportedAttributes.includes(key))
        .forEach((key) => {
          sampleToUpdate![key] = data[key];
        });

      // Update area geojson for when syncing with online
      if (sampleToUpdate.area_figure_layer && data.area_geojson) {
        data.area_geojson.properties.title = sampleToUpdate.custom_title;
        sampleToUpdate.area_figure_layer.geojson = data.area_geojson;
      }

      _updateOfflinePolySample(sampleToUpdate, offlineProject);

      await updateLocalForage();
    };

    /**
     * Ensure offline project data contains polySample & layerModel when working with poly samples.
     */
    const _updateOfflinePolySample = (
      sample: OfflineSample,
      offlineProject: OfflineProject
    ) => {
      if (!sample.area_figure_layer) {
        return;
      }

      offlineProject.polySamples[sample.area_figure_layer.id] = sample as any;

      const layerModelIndex = offlineProject.layerModels.findIndex(
        (l) => l.id === sample.area_figure_layer.id
      );

      const formattedModelLayer = {
        ...sample.area_figure_layer,
        children: [],
        visible: true,
      };

      if (layerModelIndex !== -1) {
        offlineProject.layerModels[layerModelIndex] = formattedModelLayer;
      } else {
        offlineProject.layerModels.push(formattedModelLayer);
      }
    };

    const deleteOfflineSample = async (
      id: number | string,
      project_id: number
    ) => {
      const offlineProject = offlineProjects.value.find(
        (p) => p.project_id == project_id
      );

      if (!offlineProject) {
        throw Error('Could not find project.');
      }

      const sampleIndex = offlineProject.samples.findIndex((s) => s.id == id);
      if (sampleIndex === -1) {
        throw Error(`Could not find sample with id ${id} in project.`);
      }

      const sample = offlineProject.samples[sampleIndex];
      if (sample.id) {
        offlineProject.samples.splice(sampleIndex, 1);
      }

      await updateLocalForage();
    };

    const updateOfflineSampleValues = async (
      sample_id: number | string,
      values: any[],
      project_id: number
    ) => {
      const offlineProject = offlineProjects.value.find(
        (p) => p.project_id == project_id
      );

      if (!offlineProject) {
        throw Error('Could not find project.');
      }

      const sampleToUpdate = offlineProject.samples.find(
        (s) => s.id == sample_id
      );
      if (!sampleToUpdate) {
        throw Error('Could not find sample to update values.');
      }

      sampleToUpdate.values = values;

      await updateLocalForage();
    };

    const updateLocalForage = async () => {
      const currentVuex =
        ((await localForage.getItem(STORAGE_KEY)) as any) || {};

      currentVuex.offline_projects = offlineProjects.value;

      await localForage.setItem(STORAGE_KEY, currentVuex);
    };

    return {
      offlineProjects,

      loadProjects,
      setProjects,
      getProjectById,

      createOfflineProject,
      deleteOfflineProject,
      updateOfflineProject,

      createOfflineSample,
      updateOfflineSample,
      deleteOfflineSample,
      updateOfflineSampleValues,
    };
  };
