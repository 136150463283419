<template>
  <responsive-or-slide-up
    title="Section Options"
    @close="clearSection"
    :mobileSize="mobileSize"
    v-model="showSectionOptions"
    v-if="section != null"
  >
    <template #body>
      <label class="form-label" :class="{ 'text-danger': invalidNameError }">
        <InfoButton
          v-if="invalidNameError"
          class="me-2"
          :danger="true"
          backgroundColor="#FF0000"
          :info="invalidNameError"
        />
        Section Name
      </label>
      <input
        type="text"
        class="form-control w-100 mb-3"
        :class="{ 'text-danger is-invalid': invalidNameError }"
        v-tooltip="invalidNameError"
        v-model="label"
        @keyup.enter="clearSection"
      />

      <div v-if="allowPublic" class="form-group">
        <div class="form-check mb-0">
          <input
            class="form-check-input"
            type="checkbox"
            id="public-form"
            v-model="public_form"
          />
          <label class="form-check-label" for="public-form">
            Include on Public Form?
          </label>
        </div>

        <div class="form-check mb-0">
          <input
            class="form-check-input"
            type="checkbox"
            :id="isShownOnNewPageCheckboxId"
            :disabled="isShownOnNewPageCheckboxDisabled"
            v-model="isShownOnNewPage"
          />
          <label class="form-check-label" :for="isShownOnNewPageCheckboxId">
            Show on a new page?
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="repeat"
            v-model="repeatable"
            @keyup.enter="clearSection"
          />
          <label class="form-check-label" for="repeat"> Is Repeatable </label>
        </div>
      </div>

      <div class="my-2">
        <a
          v-if="!showAdvancedOptions"
          href=""
          @click.prevent="toggleAdvancedOptions"
          >Show Advanced Options</a
        >
        <a v-else href="" @click.prevent="toggleAdvancedOptions"
          >Hide Advanced Options</a
        >
      </div>
      <div v-if="showAdvancedOptions" class="form-group mb-3">
        <label class="form-label" :class="{ 'text-danger': invalidNameError }">
          System Reference
          <InfoButton
            class="ms-2"
            info="This is the system reference used in Auto Doc code snippets.<br/><b>Tip:</b> You can put a short unique name here to avoid clashes with labels."
          />
        </label>
        <input
          type="text"
          class="form-control w-100"
          v-model="system_reference"
          :class="{ 'text-danger is-invalid': invalidNameError }"
          v-tooltip="invalidNameError"
          :placeholder="systemReferenceDefault"
          @keypress.enter="clearSection"
        />
      </div>
    </template>
  </responsive-or-slide-up>
</template>

<script>
import { checkIsInPublicForm } from '@component-library/business-logic/section';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import slugify from '@component-library/utils/slugify';
import InfoButton from '@component-library/components/InfoButton.vue';
import { getSectionReferenceError } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import auth from '@component-library/auth';

export default {
  props: [
    'value',
    'mobileSize',
    'allowPublic',
    'firstSectionInPublicForm',
    'app',
  ],
  components: { ResponsiveOrSlideUp, InfoButton },
  data: () => ({
    showSectionOptions: true,
    showAdvancedOptions: false,
    isShownOnNewPageCheckboxId: makeId(),
  }),
  computed: {
    section: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
    label: {
      get() {
        return this.value.label;
      },
      set(label) {
        this.$emit('input', { ...this.value, label });
      },
    },
    invalidNameError() {
      return getSectionReferenceError(this.value, this.app);
    },
    repeatable: {
      get() {
        return this.value.is_repeatable;
      },
      set(is_repeatable) {
        this.$emit('input', { ...this.value, is_repeatable });
      },
    },
    public_form: {
      get() {
        return this.value.is_public_form;
      },
      set(is_public_form) {
        this.$emit('input', { ...this.value, is_public_form });
      },
    },
    isShownOnNewPage: {
      get() {
        return this.value.is_shown_on_new_page;
      },
      set(value) {
        this.$emit('input', { ...this.value, is_shown_on_new_page: value });
      },
    },
    isShownOnNewPageCheckboxDisabled() {
      return (
        !checkIsInPublicForm(this.section) ||
        this.section === this.firstSectionInPublicForm
      );
    },
    system_reference: {
      get() {
        return this.value.system_reference;
      },
      set(system_reference) {
        this.$emit('input', { ...this.value, system_reference });
      },
    },
    isAdmin() {
      return auth.user().role == 'admin';
    },
    systemReferenceDefault() {
      if (!this.value.label) {
        return '';
      }
      return slugify(this.value.label);
    },
  },
  methods: {
    clearSection() {
      this.$root.$emit('clearSection');
    },
    toggleAdvancedOptions() {
      this.showAdvancedOptions = !this.showAdvancedOptions;
    },
  },
};
</script>
