import useLayerModelStore from '@/js/stores/layer-model';
import {
  findAppByFieldId,
  findAppById,
  findFieldByIdFromApp,
  getRenderableAppLinkConfig,
} from '@component-library/business-logic/app';
import type { InputValue } from '@component-library/business-logic/input-value';
import { OfflineStorageManagerReturnTypeSW } from '@component-library/composables/useOfflineStorageManagerSW';
import { FieldTypeIds } from '@component-library/fields';
import { GatherField } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import type {
  OfflineProject,
  OfflineSample,
  OfflineTemplateTab,
} from '@component-library/offline-data';
import { DEFAULT_LABEL_POSITION } from '@maps/lib/olbm/layer/sample/utils';
import { LayerType } from '@maps/lib/olbm/layer/types';
import { updateLayerModel } from '@maps/lib/olbm/layer/utils';
import { containsCoordinate } from 'ol/extent';
import createAreaFigureLayer, {
  AreaFigureLayer,
} from './modules/project/map/helpers/createAreaFigureLayer';

export const offlineMethods = {
  get: [
    {
      url: '/api/project/list',
      method: _getProjectList,
    },
    {
      url: '/api/project/details',
      method: _getProjectDetails,
    },
    {
      url: '/api/template',
      method: _getProjectTemplate,
    },
    {
      url: '/api/project/sample/get-scoped', // This config must be put in front of /api/project/sample/get or it will be served by /api/project/sample/get config
      method: _getProjectScopedSamples,
    },
    {
      url: '/api/project/sample/get-paginated',
      method: _getProjectSamplesPaginated,
    },
    {
      url: '/project/sample/get-linkable',
      method: _loadLinkableSamples,
    },
    {
      url: 'project/sample/get',
      method: _getSample,
    },
    {
      url: '/gather/phase', // For hub requests exclude /api
      method: _getProjectPhases,
    },
    {
      url: '/api/project/sample/values',
      method: _getProjectSampleValues,
    },
    {
      url: '/figure8/figure/list',
      method: _getProjectFigures,
    },
    {
      url: '/figure8/layer/list',
      method: _getProjectLayerModels,
    },
    {
      url: '/figure8/figure/sub-folders',
      method: _getProjectSubFolders,
    },
    {
      url: '/figure8/layer/sample-scope-statistics',
      method: _getProjectSampleScopeStatistics,
    },
    {
      url: 'gather/section/input-values',
      method: _getInputValuesBySection,
    },
    {
      url: 'gather/section/primary-field-input-values',
      method: _getInputValuesBySectionPrimaryField,
    },
    {
      url: 'company/users',
      method: _getCompanyUsers,
    },
  ],
  post: [
    {
      url: '/api/auth/user',
      method: _getAuthUser,
    },
    {
      url: '/api/project/import',
      method: _getProjectById,
    },
    {
      url: '/api/project/sample/update',
      method: _updateSample,
    },
    {
      url: '/api/project/sample/set-coordinates',
      method: _updateSampleCoordinates,
    },
    {
      url: '/api/project/items-from-app-title',
      method: _getItemsFromAppTitle,
    },
    {
      url: '/api/project/sample/copy-linked-app',
      method: _copyLinkedApp,
    },
    {
      url: '/api/project/layer/toggle',
      method: _updateLayerModelVisibility,
    },
    {
      url: '/api/project/ensure-gather-figure',
      method: _ensureGatherFigure,
    },
    {
      url: '/api/project/ensure-sample-groups',
      method: _ensureSampleGroups,
    },
    {
      url: '/project/sample/duplicate',
      method: _duplicateSample,
    },
  ],
  put: [
    { url: '/api/project/change-view-type', method: _updateNonSpatialView },
  ],
  delete: [
    {
      url: '/api/project/sample/delete',
      method: _deleteSample,
    },
  ],
};

export function getOfflineMethod({ method, url }) {
  return offlineMethods[method]
    ? offlineMethods[method].find((m) => url === m.url || url.includes(m.url))
    : null;
}

export async function triggerOfflineMethod(config) {
  return getOfflineMethod(config).method(config);
}

/**
 * Responses
 */
function _getAuthUser() {
  const user = localStorage.getItem('user');
  if (!user) {
    return { status: 400 };
  }

  return {
    user: JSON.parse(user),
    access_list: [],
  };
}

type OfflineRequestDefaultParams = {
  project_id: number;
};

type OfflineRequest = {
  url: string;
  params: OfflineRequestDefaultParams;
  offlineStorageManager: OfflineStorageManagerReturnTypeSW;
};

type NewItemInputValue = InputValue & { sample_id: string };

function _getProjectList({ offlineStorageManager }: OfflineRequest) {
  return {
    projects: offlineStorageManager.offlineProjects,
  };
}

function _getProjectDetails({ params, offlineStorageManager }: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  );

  if (!offlineProject) {
    return { status: 400 };
  }

  return {
    project: offlineProject,
  };
}

function _getProjectById({ url, offlineStorageManager }: OfflineRequest) {
  return {
    project: offlineStorageManager.getProjectById((url as any).split('/')[4]),
  };
}

function _getProjectTemplate({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  );

  return {
    template: offlineProject,
  };
}

type OfflineRequestGetSamplePaginated = OfflineRequest & {
  params: OfflineRequestDefaultParams & {
    templateTabIds: number[];
    query: string;
  };
};

function _getProjectSamplesPaginated({
  params,
  offlineStorageManager,
}: OfflineRequestGetSamplePaginated) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  let samples = JSON.parse(JSON.stringify(offlineProject.samples));

  if (params.templateTabIds) {
    samples = samples.filter((s) =>
      params.templateTabIds.includes(s.template_tab_id)
    );
  }

  if (params.query) {
    samples = samples.filter((s) =>
      s.custom_title.toLowerCase().includes(params.query.toLowerCase())
    );
  }

  return {
    samples: {
      data: samples,
      current_page: 1,
    },
  };
}

function _getSample({
  url,
  params,
  offlineStorageManager,
}: OfflineRequestGetSamplePaginated) {
  const { samples, template_tabs } = offlineStorageManager.getProjectById(
    params.project_id
  )!;
  const sampleId = url.split('/').at(-1);
  // The type of id could be either string or number so use == here.
  const sample = samples.find((s) => s.id == sampleId);
  return sample
    ? {
        ...sample,
        input_values_for_linking: getInputValuesForLinking(
          template_tabs,
          sample
        ),
      }
    : null;
}

function _getProjectPhases({ params, offlineStorageManager }: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  return {
    phases: offlineProject.phases,
  };
}

function _getProjectSampleValues({
  url,
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  const sample = offlineProject.samples.find((s) => s.id == url.split('/')[5])!;

  return {
    sample: sample,
    input_values: sample.values,
  };
}

function _getSampleGroupByTab(project, id) {
  if (!id) {
    return null;
  }

  const dataTab = project.template_tabs.find((t) => t.id === id);
  if (!dataTab) {
    return null;
  }

  const sampleGroup = project.layerModels.find(
    (g) =>
      g.marker_identifier == dataTab.point_icon + '_' + dataTab.drawing_colour
  );

  return sampleGroup?.id || null;
}

type OfflineRequestUpdateSample = OfflineRequest & {
  data: {
    sample_id: number | string;
    id: number | string;
    template_tab_id: number;
    area_geojson?: any;
    area_figure_layer: AreaFigureLayer;
    custom_title: string;
    input_values: any[];
    latlng?: { lat: number; lng: number };
    icon_rotation?: number;
  };
};

async function _updateSample({
  data,
  params,
  offlineStorageManager,
}: OfflineRequestUpdateSample) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  if (!data.sample_id) {
    data.id = _getUniqueSampleId(offlineProject);

    if (data.area_geojson) {
      data.area_figure_layer = createAreaFigureLayer(
        data.area_geojson,
        data.custom_title
      );
    }

    data.input_values.forEach((iV) => {
      iV.sample_id = data.sample_id;
    });

    const newSample: any = {
      ...data,
      project_figure_layer_id: !data.area_figure_layer
        ? _getSampleGroupByTab(offlineProject, data.template_tab_id)
        : data.area_figure_layer.id,
      is_created_offline: true,
    };

    delete newSample.sample_id;
    delete newSample.input_values;

    await offlineStorageManager.createOfflineSample(
      newSample,
      params.project_id
    );

    data.input_values.forEach((iV) => {
      iV.sample_id = data.id;
    });

    data.sample_id = data.id;
  } else {
    await offlineStorageManager.updateOfflineSample(
      {
        ...data,
        is_edited_offline: true,
      } as any,
      params.project_id
    );
  }

  await offlineStorageManager.updateOfflineSampleValues(
    data.sample_id,
    data.input_values,
    params.project_id
  );

  const updatedSample = offlineProject.samples.find(
    (s) => s.id == data.sample_id
  );

  return {
    sample: updatedSample,
    input_values: data.input_values,
    area_figure_layer: updatedSample?.area_figure_layer || null,
    samples_count: offlineProject.samples.filter(
      (s) => s.template_tab_id === data.template_tab_id
    ).length,
  };
}

// generate a unique sample id with-out any collisions
function _getUniqueSampleId(project: OfflineProject) {
  const randomId = ('000000000' + makeId()).slice(-9);

  if (
    project.samples.findIndex((s) => s.id && s.id.toString() == randomId) != -1
  ) {
    return _getUniqueSampleId(project);
  }

  return randomId;
}

type OfflineRequestUpdateCoordinates = OfflineRequest & {
  data: {
    sample_id: number | string;
    template_tab_id: number;
    geojson?: any;
    latlng: { lat: number; lng: number };
    label_position?: string;
    icon_rotation?: number;
  };
};

async function _updateSampleCoordinates({
  data,
  params,
  offlineStorageManager,
}: OfflineRequestUpdateCoordinates) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  const fieldsToUpdate: any = {
    sample_id: data.sample_id,
    template_tab_id: data.template_tab_id,
  };

  if (data.geojson) {
    const sampleToUpdate = offlineProject.samples.find(
      (s) => s.id == data.sample_id
    )!;

    fieldsToUpdate.area_figure_layer = {
      ...sampleToUpdate.area_figure_layer,
      geojson: data.geojson,
    };
  } else {
    fieldsToUpdate.latitude = data.latlng.lat;
    fieldsToUpdate.longitude = data.latlng.lng;
    fieldsToUpdate.label_position =
      data.label_position ?? DEFAULT_LABEL_POSITION;
    fieldsToUpdate.icon_rotation = data.icon_rotation;
  }

  await offlineStorageManager.updateOfflineSample(
    fieldsToUpdate,
    params.project_id
  );

  return {
    sample: offlineProject.samples.find((s) => s.id == data.sample_id),
  };
}

async function _deleteSample({
  url,
  params,
  offlineStorageManager,
}: OfflineRequest) {
  await offlineStorageManager.deleteOfflineSample(
    url.split('/')[5],
    params.project_id
  );

  return {};
}

type OfflineRequestDuplicateSample = OfflineRequest & {
  data: {
    sample_id: number;
    position: { lat: number; lng: number };
    label_position?: string;
  };
};

//Logic from SampleCategory.php -> duplicateSample
async function _duplicateSample({
  data,
  params,
  offlineStorageManager,
}: OfflineRequestDuplicateSample) {
  const { project_id: projectId } = params;
  const { sample_id: sampleId, position, label_position: labelPosition } = data;
  try {
    const duplicatedSample = await _duplicateSampleInternal(
      offlineStorageManager,
      projectId,
      sampleId,
      position,
      labelPosition
    );
    return {
      sample: duplicatedSample,
    };
  } catch (e) {
    console.error(e);
    return { status: 400 };
  }
}

const DEFAULT_SAMPLE_DUPLICATION_OPTIONS = {
  shouldAppendCopy: true,
  adaptData: (data: OfflineSample): OfflineSample => {
    return data;
  },
  adaptValue: (value, field) => {
    delete value.id;
    return value;
  },
};

async function _duplicateSampleInternal(
  offlineStorageManager: OfflineStorageManagerReturnTypeSW,
  projectId: number,
  sampleId: number | string,
  position: { lat: number | undefined; lng: number | undefined } = {
    lat: undefined,
    lng: undefined,
  },
  labelPosition: any = DEFAULT_LABEL_POSITION,
  options: {
    shouldAppendCopy: boolean;
    adaptData: (data: OfflineSample) => OfflineSample;
    adaptValue: (
      value: NewItemInputValue,
      field: GatherField
    ) => NewItemInputValue;
  } = DEFAULT_SAMPLE_DUPLICATION_OPTIONS
): Promise<OfflineSample> {
  const { shouldAppendCopy, adaptData, adaptValue } = options;
  const offlineProject = offlineStorageManager.getProjectById(projectId)!;

  const sampleToDuplicate = offlineProject.samples.find(
    (s) => s.id == sampleId
  );

  if (!sampleToDuplicate) {
    throw new Error(`The sample with id ${sampleId} was not found.`);
  }

  const newId = _getUniqueSampleId(offlineProject);
  const app = sampleToDuplicate.template_tab_id
    ? offlineProject.template_tabs.find(
        (app) => app.id === sampleToDuplicate.template_tab_id
      )
    : undefined;
  const duplicatedSample = adaptData({
    ...sampleToDuplicate,
    id: newId,
    latitude: position.lat ? position.lat : sampleToDuplicate.latitude,
    longitude: position.lng ? position.lng : sampleToDuplicate.longitude,
    label_position: labelPosition,
    custom_title: shouldAppendCopy
      ? `${sampleToDuplicate.custom_title} - copy`
      : sampleToDuplicate.custom_title,
    is_edited_offline: false,
    is_created_offline: true,
    offline_user_id: null,
    values: sampleToDuplicate.values.map((v) => {
      if (!app) {
        throw new Error(
          `The app with id ${sampleToDuplicate.template_tab_id} was not found.`
        );
      }
      // @ts-ignore template_field_id definitely exists in a value.
      const { template_field_id: fieldId } = v;
      const field = findFieldByIdFromApp(app, fieldId);
      if (!field) {
        throw new Error(`The field with id ${fieldId} was not found.`);
      }
      return adaptValue(
        {
          ...v,
          sample_id: newId,
        },
        field
      );
    }),
  });

  await offlineStorageManager.createOfflineSample(duplicatedSample, projectId);

  return duplicatedSample;
}

type OfflineRequestGetItemsFromAppTitle = OfflineRequest & {
  data: {
    appTitle: string;
    linked_item_id?: number | null;
    is_for_count?: boolean;
  };
};

function _getItemsFromAppTitle({
  data,
  params,
  offlineStorageManager,
}: OfflineRequestGetItemsFromAppTitle) {
  const {
    appTitle,
    linked_item_id: linkedItemId,
    is_for_count: isForCount,
  } = data;
  const { project_id: projectId } = params;
  const offlineProject = offlineStorageManager.getProjectById(projectId)!;
  const { template_tabs: apps, samples: allItems } = offlineProject;
  const app = apps.find((_app) => _app.title === appTitle);

  if (!app) {
    if (!isForCount) {
      return {
        items: [],
      };
    } else {
      return {
        count: 0,
      };
    }
  }

  let items = allItems.filter((item) => item.template_tab_id === app.id);
  (() => {
    if (!linkedItemId) {
      return;
    }
    const linkedItem = allItems.find((item) => item.id === linkedItemId);
    if (!linkedItem) {
      console.warn(`The linked item with id ${linkedItemId} was not found.`);
      items = [];
      return;
    }
    const linkedApp = apps.find((app) => app.id === linkedItem.template_tab_id);
    if (!linkedApp) {
      console.warn(
        `The linked app with id ${linkedItem.template_tab_id} was not found.`
      );
      items = [];
      return;
    }
    items = items.filter((item) =>
      item.values.some((value) => {
        // @ts-ignore template_field_id definitely exists in a value.
        const field = findFieldByIdFromApp(app, value.template_field_id);
        if (!field) {
          return false;
        }
        return (
          field.field_type_id === FieldTypeIds.REFERENCE &&
          field.options?.template_tab_title === linkedApp.title &&
          value.value == linkedItemId
        );
      })
    );
  })();

  if (!isForCount) {
    return {
      items,
    };
  } else {
    return {
      count: items.length,
    };
  }
}

type OfflineRequestCopyLinkedApp = OfflineRequest & {
  data: {
    source_item_id: number;
    linked_app_id: number;
    target_item_id: number;
  };
};

async function _copyLinkedApp({
  data,
  params,
  offlineStorageManager,
}: OfflineRequestCopyLinkedApp) {
  const {
    source_item_id: sourceItemId,
    linked_app_id: linkedAppId,
    target_item_id: targetItemId,
  } = data;
  const { project_id: projectId } = params;
  const offlineProject = offlineStorageManager.getProjectById(projectId)!;
  const { template_tabs: apps, samples: allItems } = offlineProject;

  const sourceItem = allItems.find((item) => item.id === sourceItemId);
  if (!sourceItem) {
    throw new Error(`The source item with id ${sourceItemId} was not found.`);
  }
  const targetItem = allItems.find((item) => item.id === targetItemId);
  if (!targetItem) {
    throw new Error(`The target item with id ${targetItemId} was not found.`);
  }
  const app = apps.find((app) => app.id === sourceItem.template_tab_id);
  if (!app) {
    throw new Error(
      `The app with id ${sourceItem.template_tab_id} was not found.`
    );
  }
  const linkedApp = apps.find((app) => app.id === linkedAppId);
  if (!linkedApp) {
    throw new Error(`The linked app with id ${linkedApp} was not found.`);
  }
  const linkedItems = allItems.filter((item) => {
    if (item.template_tab_id !== linkedAppId) {
      return false;
    }

    return item.values.some((value) => {
      // @ts-ignore template_field_id definitely exists in a value.
      const { template_field_id: fieldId } = value;
      const field = findFieldByIdFromApp(linkedApp, fieldId);
      if (!field) {
        throw new Error(`The field with id ${fieldId} was not found.`);
      }
      return (
        field.field_type_id === FieldTypeIds.REFERENCE &&
        field.options?.template_tab_title === app.title &&
        value.value == sourceItemId
      );
    });
  });
  const copies: OfflineSample[] = [];
  const options = {
    shouldAppendCopy: false,
    adaptData(data: OfflineSample): OfflineSample {
      return {
        ...data,
        custom_title: targetItem.custom_title,
      };
    },
    adaptValue(
      value: NewItemInputValue,
      field: GatherField
    ): NewItemInputValue {
      value = DEFAULT_SAMPLE_DUPLICATION_OPTIONS.adaptValue(value, field);
      if (
        field.field_type_id === FieldTypeIds.REFERENCE &&
        field.options?.template_tab_title === app.title
      ) {
        return { ...value, value: targetItemId };
      }
      return value;
    },
  };
  for (const linkedItem of linkedItems) {
    const copy = await _duplicateSampleInternal(
      offlineStorageManager,
      projectId,
      linkedItem.id,
      { lat: linkedItem.latitude, lng: linkedItem.longitude },
      linkedItem.label_position,
      options
    );
    copies.push(copy);
  }
  return {
    copies,
  };
}

type OfflineRequestUpdateLayerVisibility = OfflineRequest & {
  data: {
    layer_id: number;
    visible: boolean;
  };
};

async function _updateLayerModelVisibility({
  data,
  params,
  offlineStorageManager,
}: OfflineRequestUpdateLayerVisibility) {
  const { layer_id: layerModelId, visible } = data;

  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  const newLayerModels = updateLayerModel(
    offlineProject.layerModels,
    layerModelId,
    { visible }
  );

  await offlineStorageManager.updateOfflineProject(params.project_id, {
    layerModels: newLayerModels,
  });

  return {};
}

function _ensureGatherFigure({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  if (!offlineProject.figures.some((figure) => figure.gather_access)) {
    throw `The gather figure doesn't exist.`;
  }

  return {};
}

function _ensureSampleGroups({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  const sampleGroups = offlineProject.layerModels.filter(
    (l) => l.geojson.properties.type === LayerType.SAMPLE_GROUP
  );

  const apps = offlineProject.template_tabs.filter((app) =>
    ['any', 'point', 'non-spatial'].includes(app.drawing_type)
  );

  for (let app of apps) {
    const ralc = getRenderableAppLinkConfig(app, apps);
    let markerIdentifier;
    if (ralc) {
      const { icon, color } = ralc;
      markerIdentifier = `${icon}_${color}`;
    } else if (app.drawing_type === 'any') {
      markerIdentifier = '0_#000000';
    } else if (app.drawing_type === 'point') {
      const { point_icon: icon, drawing_colour: color } = app;
      markerIdentifier = `${icon}_${color}`;
    } else {
      continue;
    }
    const [sampleGroup] = sampleGroups.filter(
      (sampleGroup) => sampleGroup.marker_identifier === markerIdentifier
    );

    if (!sampleGroup) {
      throw `The sample group ${markerIdentifier} doesn't exist.`;
    }
  }

  return {};
}

function _updateNonSpatialView({ data }) {
  const { isNonSpatialView } = data;

  // store.dispatch('updateProject', {
  //   is_gather_non_spatial_view: isNonSpatialView,
  // });
}

function _getProjectFigures({ params, offlineStorageManager }: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  const { figures, figureStylingRules, appStylingRules } = offlineProject;

  return {
    figures,
    figure_styling_rules: figureStylingRules,
    template_tab_styling_rules: appStylingRules,
  };
}

function _getProjectLayerModels({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  const { layerModels, polySamples } = offlineProject;

  return {
    layers: layerModels,
    poly_gather_samples: polySamples,
  };
}

function _getProjectSubFolders({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const offlineProject = offlineStorageManager.getProjectById(
    params.project_id
  )!;

  return offlineProject.subFolders;
}

type OfflineRequestGetProjectScopedSamples = OfflineRequest & {
  params: {
    project_id: number;
    sample_group_id: number;
    extent: string;
  };
};

function _getProjectScopedSamples({
  params,
  offlineStorageManager,
}: OfflineRequestGetProjectScopedSamples) {
  const { project_id, sample_group_id, extent: _extent } = params;
  const offlineProject = offlineStorageManager.getProjectById(project_id)!;

  const layerModelStore = useLayerModelStore();
  const defaultSampleGroup = layerModelStore.getDefaultSampleGroup();
  const isDefault = defaultSampleGroup.id === sample_group_id;
  const extent = _extent.split(',').map((value) => parseFloat(value));

  return offlineProject.samples.filter((sample) => {
    return (
      ((!sample.project_figure_layer_id && isDefault) ||
        sample.project_figure_layer_id === sample_group_id) &&
      containsCoordinate(extent, [sample.longitude, sample.latitude])
    );
  });
}

function _getProjectSampleScopeStatistics({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const { samples } = offlineStorageManager.getProjectById(params.project_id)!;

  const layerModelStore = useLayerModelStore();
  const sampleGroups = layerModelStore.findLayerModelsByType(
    LayerType.SAMPLE_GROUP
  );
  const defaultSampleGroup = layerModelStore.getDefaultSampleGroup();

  return sampleGroups.reduce((accu: any[], sampleGroup) => {
    const isDefault = defaultSampleGroup.id === sampleGroup.id;
    const samplesOfSampleGroup = samples.filter((sample) => {
      return (
        sample.project_figure_layer_id === sampleGroup.id ||
        (isDefault && !sample.project_figure_layer_id)
      );
    });

    const subFolders = samplesOfSampleGroup
      .map((sample) => sample.sub_folder)
      .filter((subFolder) => !!subFolder);

    accu.push({
      sampleGroupId: sampleGroup.id,
      sampleCount: samplesOfSampleGroup.length,
      subFolders: subFolders.reduce((accu: any[], subFolder) => {
        const samplesOfSubFolder = samplesOfSampleGroup.filter(
          (sample) => sample.sub_folder === subFolder
        );
        accu.push({
          name: subFolder,
          sampleCount: samplesOfSubFolder.length,
        });
        return accu;
      }, []),
    });
    return accu;
  }, []);
}

function checkIsLinkedSample(
  sampleId: number | string | null,
  linkFieldId: number,
  sample: OfflineSample
): boolean {
  if (sampleId === null) {
    return false;
  }

  return sample.values.some(
    // @ts-ignore template_field_id definitely exists in a value.
    (v) => v.template_field_id === linkFieldId && v.value == sampleId
  );
}

function checkIsLinkingPendingSample(
  linkFieldId: number,
  linkedApp,
  sample: OfflineSample
): boolean {
  return (
    sample.template_tab_id === linkedApp.id &&
    // @ts-ignore template_field_id definitely exists in a value.
    !sample.values.some((v) => v.template_field_id === linkFieldId)
  );
}

function getInputValuesForLinking(
  apps: OfflineTemplateTab[],
  sample: OfflineSample
): InputValue[] {
  return sample.values.filter((v) => {
    const { template_tab_id } = sample;
    if (!template_tab_id) {
      return false;
    }

    const app = findAppById(apps, template_tab_id);
    if (!app) {
      return false;
    }

    // @ts-ignore template_field_id definitely exists in a value.
    const field = findFieldByIdFromApp(app, v.template_field_id);
    if (!field) {
      return false;
    }

    return field.field_type_id === FieldTypeIds.REFERENCE;
  });
}

function _normalizeSampleId(
  sampleId: number | string | null
): number | string | null {
  if (typeof sampleId !== 'string') {
    return sampleId;
  }

  if (/^\d+$/.test(sampleId)) {
    return parseInt(sampleId, 10);
  }

  return sampleId;
}

function _loadLinkableSamples({
  url,
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const { template_tabs: apps, samples } = offlineStorageManager.getProjectById(
    params.project_id
  )!;
  const parsedUrl = new URL(`${import.meta.env.VITE_APP_URL}${url}`);

  const sampleId = _normalizeSampleId(parsedUrl.searchParams.get('sampleId'));

  const linkFieldIdParam = parsedUrl.searchParams.get('link_field_id');
  if (linkFieldIdParam === null) {
    throw 'The link_field_id parameter is null.';
  }
  const linkFieldId = parseInt(linkFieldIdParam, 10);
  const linkedApp = findAppByFieldId(apps, linkFieldId);
  if (!linkedApp) {
    throw 'The linked app is undefined.';
  }

  const pageParam = parsedUrl.searchParams.get('page');
  if (pageParam === null) {
    throw 'The page parameter is null.';
  }
  const page = parseInt(pageParam, 10);

  const pageSize = 10;
  const linkableSamples = samples.filter(
    (s) =>
      checkIsLinkedSample(sampleId, linkFieldId, s) ||
      checkIsLinkingPendingSample(linkFieldId, linkedApp, s)
  );
  const linkableSamplesOfPage = linkableSamples
    .slice((page - 1) * pageSize, page * pageSize)
    .map((s) => {
      return {
        ...s,
        input_values_for_linking: getInputValuesForLinking(apps, s),
      };
    });
  const pageCount = Math.ceil(linkableSamples.length / pageSize);
  const linkedSamples = linkableSamples.filter((s) =>
    checkIsLinkedSample(sampleId, linkFieldId, s)
  );
  return {
    pagination: {
      data: linkableSamplesOfPage,
      current_page: page,
      last_page: pageCount,
      next_page_url:
        page < pageCount ? url.replace(/page=\d+/, `page=${page + 1}`) : null,
    },
    allLinkedItemsCount: linkedSamples.length,
  };
}

function _getInputValuesBySection({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const {
    project_id: projectId,
    sample_ids: sampleIds,
    tab_id: appId,
    section_id: sectionId,
    section_index: sectionIndex,
  } = params as any;
  const project = offlineStorageManager.getProjectById(projectId);
  if (!project) {
    throw `The project with id ${projectId} was not found.`;
  }
  const [sampleId] = sampleIds;
  const sample = project.samples.find((s) => s.id === sampleId);
  if (!sample) {
    throw `The sample with id ${sampleId} was not found.`;
  }
  const inputValues = (sample.values as any[]).filter(
    (v) =>
      v.template_tab_id === appId &&
      v.template_section_id === sectionId &&
      v.template_section_index === sectionIndex
  );
  return {
    input_values: inputValues,
  };
}

function findSection(apps, sectionId): any {
  for (let i = 0; i < apps.length; i++) {
    const section = apps[i].sections.find((s) => s.id === sectionId);
    if (section) {
      return section;
    }
  }
}

function _getInputValuesBySectionPrimaryField({
  params,
  offlineStorageManager,
}: OfflineRequest) {
  const {
    project_id: projectId,
    section_id: sectionId,
    sample_id: sampleId,
  } = params as any;
  const project = offlineStorageManager.getProjectById(projectId);
  if (!project) {
    throw `The project with id ${projectId} was not found.`;
  }
  const section = findSection(project.template_tabs, sectionId);
  if (!section) {
    throw `The section with id ${sectionId} was not found.`;
  }
  let { primary_field_id: primaryFieldId } = section;
  if (!primaryFieldId) {
    primaryFieldId = section.template_fields.find(
      (f) => f.id !== FieldTypeIds.COPY_DATA_LINK
    )?.id;
  }
  if (!primaryFieldId) {
    throw `There were no fields in the section with id $sectionId.`;
  }
  const sample = project.samples.find((s) => s.id === sampleId);
  if (!sample) {
    throw `The sample with id ${sampleId} was not found.`;
  }
  const primaryFieldInputValues = (sample.values as any[]).filter(
    (v) =>
      v.template_section_id === sectionId &&
      v.template_field_id === primaryFieldId
  );
  primaryFieldInputValues.sort((pfiv1, pfiv2) => pfiv1.order - pfiv2.order);
  const sectionIndexes = (sample.values as any[]).reduce((accu, v) => {
    if (v.template_section_id === sectionId) {
      accu.push(v.template_section_index);
    }
    return accu;
  }, [] as number[]);
  const max = sectionIndexes.length > 0 ? Math.max(...sectionIndexes) : -1;
  const result: any[] = [];
  for (let i = 0; i <= max; i++) {
    const pfiv =
      primaryFieldInputValues.find(
        (_pfiv) => _pfiv.template_section_index === i
      ) ?? null;
    result.push(pfiv);
  }
  return result;
}

function _getCompanyUsers({ offlineStorageManager }: OfflineRequest) {
  const projectId = localStorage.getItem('project_id');
  if (projectId === null) {
    throw 'The project id was not set in localStorage.';
  }

  const project = offlineStorageManager.getProjectById(parseInt(projectId));
  if (!project) {
    throw `The project with id ${projectId} was not found.`;
  }

  return {
    users: project.company_users || [],
  };
}
