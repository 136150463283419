<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{ value?: number; isResetAvailable?: boolean }>(),
  {
    value: 1,
    isResetAvailable: true,
  }
);

const emit = defineEmits<{
  (e: 'input', value: number): void;
  (e: 'reset'): void;
}>();

const title = computed<string>(() => `${Math.round(props.value * 100)}%`);

function handleInput(event: Event) {
  emit('input', parseFloat((event.target as HTMLInputElement).value));
}
</script>

<template>
  <div
    class="d-flex flex-column align-items-center bg-light p-3 px-4 rounded-2"
  >
    <label class="form-label mb-1">
      Icon Opacity <span class="fw-medium"> ({{ title }}) </span>
      <a
        v-if="isResetAvailable"
        href="#"
        type="button"
        class="fw-medium ms-1"
        @click.prevent="emit('reset')"
      >
        Reset
      </a>
    </label>
    <input
      type="range"
      class="form-range"
      :min="0"
      :max="1"
      :step="0.01"
      :value="value"
      :title="title"
      @input="handleInput"
    />
  </div>
</template>
