<script lang="ts" setup>
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import { GatherField } from '@component-library/gather';
import { computed } from 'vue';

const props = defineProps<{
  field: GatherField;
}>();

const emit = defineEmits<{
  (event: 'update', payload: { key: string; value: any }): void;
  (event: 'blur'): void;
}>();

const defaultText = computed({
  get() {
    return props.field.options?.default || '';
  },
  set(updated) {
    emit('update', {
      key: 'default',
      value: updated,
    });
  },
});

const validation = computed({
  get() {
    return props.field.options?.validation || null;
  },
  set(updated) {
    emit('update', {
      key: 'validation',
      value: updated,
    });
  },
});

const is_richtext = computed({
  get() {
    return props.field.options?.is_richtext;
  },
  set(updated) {
    emit('update', {
      key: 'is_richtext',
      value: updated,
    });
  },
});
</script>

<template>
  <div>
    <InputCheckbox
      v-if="!props.field.options?.is_readonly"
      label="Do you want text styling options?"
      v-model="is_richtext"
    />
    <div class="form-group pt-3">
      <label class="form-label">Validation Requirement</label>
      <div class="input-group">
        <select class="form-control" v-model="validation">
          <option :value="null">None</option>
          <option value="email">Email Address</option>
        </select>
      </div>
    </div>
    <div class="form-group pt-3">
      <label class="form-label">Default Value</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          v-model="defaultText"
          placeholder="Default text"
          @keypress.enter="emit('blur')"
        />
      </div>
    </div>
  </div>
</template>
