export enum DrawingType {
  Any = 'any',
  Point = 'point',
  Polygon = 'polygon',
  Polyline = 'polyline',
  NonSpatial = 'non-spatial',
}

export type CollectionType =
  | DrawingType.Point
  | DrawingType.Polygon
  | DrawingType.Polyline;

export enum Operator {
  EqualTo = '==',
  NotEqualTo = '!=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
}
