import { externalClient } from '@component-library/api';
import { createRequestOptions, proxify } from '../../../common/network';
import type {
  FeatureServerFolderLayerModel,
  FeatureServerItemLayerModel,
} from './types';

declare const axios: any;

export default async function requestFeatureServerLayerRenderer(
  folderModel: FeatureServerFolderLayerModel,
  itemModel: FeatureServerItemLayerModel
) {
  const { properties } = folderModel.geojson;
  const { shouldUseCorsProxy, token } = properties;

  let { url } = properties;

  url += `/${itemModel.geojson.properties.index}?f=pjson`;

  if (token) {
    url += '&token=' + token;
  }

  if (shouldUseCorsProxy) {
    url = proxify(url);
  }

  const response = await externalClient.get(url, createRequestOptions());

  const {
    data: { name: title, drawingInfo },
  } = response;
  return { id: itemModel.id, title, renderer: drawingInfo?.renderer };
}
