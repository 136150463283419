<template>
  <div>
    <Drawer v-if="mobileSize" ref="drawer">
      <template #header>
        <h6 class="mb-0">
          {{
            selectedField
              ? selectedField.label
              : selectedSection
              ? selectedSection.label
              : ''
          }}
          Options
        </h6>
      </template>
      <template>
        <div id="mobile-template-options" class="px-4 pb-4"></div>
      </template>
    </Drawer>
    <div
      v-if="tab"
      class="row position-relative"
      :class="{
        disabled: disabled,
      }"
    >
      <div
        v-if="!disabled && !mobileSize"
        class="col-lg-3 mb-5 pb-5"
        :style="
          mobileSize
            ? ''
            : 'max-height: 90vh; overflow-y: auto; top: 4rem; position: sticky'
        "
      >
        <div class="mb-3">
          <TemplateAvailableDraggables
            :template="tab"
            v-model="sections"
            :disabled="disabled == true"
            :selectedSection="sectionIndex"
            :isTemplateEditorOperating="isTemplateEditorOperating"
            :mobileSize="mobileSize"
            @addSection="addSection"
            @addField="createField"
          />
        </div>
      </div>
      <div
        :class="{
          'col-lg-6': !disabled,
          'col-lg-9': disabled,
        }"
        :style="mobileSize ? 'margin-bottom: 80px' : ''"
      >
        <button
          v-if="hasHSSection"
          type="button"
          class="btn btn-outline-primary btn-lg w-100 mb-3"
          @click="showHSCategoryModal = true"
        >
          Select H&S Categories
        </button>

        <draggable
          :group="sectionsDraggableGroup"
          :value="sections"
          :disabled="disabled"
          :sort="!isTemplateEditorOperating"
          class="h-100 p-0"
          draggable=".template-section.draggable-header"
          ghost-class="section-header-draggable"
          @change="handleSectionsChange"
          handle=".section-header"
          :key="`section-draggable-${sections.length}`"
        >
          <template v-if="isLoadingSections">
            <ProgressBar
              :progress="loadingProgress"
              title="Loading Sections..."
            />
          </template>
          <template v-else-if="sections.length">
            <TemplateSectionDraggable
              v-for="(section, i) of sections"
              :key="`section-${section.id}`"
              class="mb-3"
              v-bind:index.sync="fieldIndex"
              :value="section"
              :isUpdating="section.isUpdating"
              :isDuplicating="section.isDuplicating"
              @input="updateSection"
              :sectionIndex="i"
              :tab="tab"
              :tabs="tabs"
              :disabled="disabled || section.isDeleting"
              :selected="sectionIndex == i"
              :isTemplateEditorOperating="isTemplateEditorOperating"
              :mobileSize="mobileSize"
              @selectSection="selectSection"
              @createField="createField"
              @updateField="updateField"
              @updateFieldLabel="updateFieldLabel"
              @delete="removeSection(i)"
              @duplicate="duplicateSection(i)"
              @deleteField="handleDeleteField"
              @openAddFields="() => (showFieldModal = true)"
            />
          </template>

          <div
            v-else
            class="text-center d-flex align-items-center justify-content-center flex-column py-5"
          >
            <h1 class="fal fa-line-columns"></h1>
            <h6 class="mb-0">
              {{
                mobileSize
                  ? 'Click the "+" to get started'
                  : 'Drag a section here to get started'
              }}
            </h6>
          </div>
        </draggable>
      </div>

      <div
        id="desktop-template-options"
        class="col-md-5 col-lg-3 position-sticky"
        style="z-index: 3; top: 4rem"
        :style="mobileSize ? '' : 'max-height: 90vh; overflow-y: auto'"
      ></div>
    </div>
    <Teleport
      :to="
        mobileSize ? '#mobile-template-options' : '#desktop-template-options'
      "
    >
      <div class="sticky-container">
        <slot name="template-tab-editor"></slot>

        <template v-if="selectedField && !disabled">
          <TemplateFieldOptions
            v-if="!selectedSection.is_gps_point_metadata"
            v-bind:fieldIndex.sync="fieldIndex"
            :sectionField="selectedField"
            :sectionIndex="sectionIndex"
            @updateField="updateField"
            @updateFieldOptions="updateFieldOptions"
            :value="selectedSection"
            @input="updateSection"
            @add="addCondition"
            @updateTab="(data) => $emit('updateTab', data)"
            :tab="tab"
            :mobileSize="mobileSize"
            @clearField="
              fieldIndex = null;
              sectionIndex = null;
            "
            @showExpressionEditor="showEditor"
            class="mb-3"
            :key="`field-options-${selectedField.id}`"
          />
          <GpsPointMetadataSectionFieldOptions
            v-else
            v-bind:fieldIndex.sync="fieldIndex"
            :sectionField="selectedField"
            :sectionIndex="sectionIndex"
            @updateField="updateField"
            @updateFieldOptions="updateFieldOptions"
            :value="selectedSection"
            @input="updateSection"
            @add="addCondition"
            :tab="tab"
            :mobileSize="mobileSize"
            @clearField="
              fieldIndex = null;
              sectionIndex = null;
            "
            @showExpressionEditor="showEditor"
            class="mb-3"
          />
        </template>

        <template v-else-if="selectedSection && !disabled">
          <TemplateSectionOptions
            v-if="!selectedSection.is_gps_point_metadata"
            class="mb-3"
            :value="selectedSection"
            @input="updateSection"
            :app="tab"
            :mobileSize="mobileSize"
            :allowPublic="hasPublicLink"
            :firstSectionInPublicForm="firstSectionInPublicForm"
            :key="`section-options-${selectedSection.id}`"
          />
          <GpsPointMetadataSectionOptions
            v-else
            class="mb-3"
            :value="selectedSection"
            :mobileSize="mobileSize"
          />
        </template>

        <PublicFormOptions
          v-if="hasPublicLink"
          class="mb-3"
          :tab="tab"
          :publicSectionCount="
            sections.filter(
              (s) =>
                s.is_public_form ||
                s.template_fields.some((f) => f.options.is_public_form)
            ).length
          "
          :mobileSize="mobileSize"
        />

        <div class="mb-2" v-if="showTabSettings">
          <div
            class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-2"
          >
            <h6 class="mb-0">Template Settings</h6>
          </div>

          <label class="w-100">
            Identifier Prefix
            <input
              class="form-control"
              v-model="identifier_prefix"
              placeholder="E.g. HA, Photo, Item..."
            />
          </label>

          <div class="form-check float-end">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="identifier_increment"
              id="identifier_increment"
            />
            <label class="form-check-label" for="identifier_increment">
              Autoincrement
            </label>
          </div>
        </div>

        <button
          v-if="mobileSize && !disabled"
          type="button"
          class="btn btn-light w-100 p-3 fw-bold mb-2"
          @click="addMobileSections"
        >
          Add Sections
        </button>

        <button
          type="button"
          class="btn btn-light w-100 p-3 fw-bold"
          @click="() => (showTemplatePreviewModal = true)"
        >
          Preview App
        </button>

        <template v-if="project">
          <button
            type="button"
            class="btn btn-light w-100 p-3 mt-2 fw-bold"
            @click="openGatherSupportPage"
          >
            Learn more about Gather Apps?
          </button>
          <button
            type="button"
            class="btn btn-primary w-100 p-3 mt-2 mb-3 fw-bold"
            @click="gotoMap"
          >
            Finish & Go To Gather
          </button>
        </template>
      </div>
    </Teleport>

    <TemplatePreviewModal
      :show="showTemplatePreviewModal"
      :dataTab="tab"
      @close="() => (showTemplatePreviewModal = false)"
    />

    <ExpressionEditorModal
      v-if="selectedSection && showExpressionEditorModal"
      :dataTab="tab"
      :value="selectedField"
      :sections="sections"
      @updateExpression="updateExpression"
      @close="() => (showExpressionEditorModal = false)"
    />

    <CategorySelectorModal
      v-if="showHSCategoryModal"
      :tab="tab"
      :updateTab="
        (categories) =>
          $emit('updateTab', {
            key: 'hs_categories',
            value: categories,
          })
      "
      @close="showHSCategoryModal = false"
    />

    <Modal v-if="mobileSize && showFieldModal" @close="showFieldModal = false">
      <TemplateAvailableDraggables
        :template="tab"
        v-model="sections"
        :disabled="disabled == true"
        :selectedSection="sectionIndex"
        :isTemplateEditorOperating="isTemplateEditorOperating"
        :mobileSize="mobileSize"
        @addSection="addSection"
        @addField="createField"
      />

      <template #footer>
        <a class="btn btn-primary w-100" @click="showFieldModal = false">
          Done
        </a>
      </template>
    </Modal>
  </div>
</template>

<script>
import Section from '@/js/classes/Section.js';
import { findAppByFieldId } from '@component-library/business-logic/app';
import { checkIsInPublicForm } from '@component-library/business-logic/section';
import Drawer from '@component-library/components/Drawer.vue';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import Modal from '@component-library/components/Modal.vue';
import ProgressBar from '@component-library/components/ProgressBar.vue';
import { DATANEST_URL } from '@component-library/env';
import { FieldTypeIds } from '@component-library/fields';
import _debounce from 'lodash/debounce';
import _omit from 'lodash/omit';
import Draggable from 'vuedraggable';
import ExpressionEditorModal from '../expression/Modal.vue';
import CategorySelectorModal from '../health-and-safety/CategorySelectorModal.vue';
import GpsPointMetadataSectionFieldOptions from './GpsPointMetadataSectionFieldOptions.vue';
import GpsPointMetadataSectionOptions from './GpsPointMetadataSectionOptions.vue';
import PublicFormOptions from './PublicFormOptions.vue';
import TemplateAvailableDraggables from './TemplateAvailableDraggables.vue';
import TemplateFieldOptions from './TemplateFieldOptions.vue';
import TemplatePreviewModal from './TemplatePreviewModal.vue';
import TemplateSectionDraggable from './TemplateSectionDraggable.vue';
import TemplateSectionOptions from './TemplateSectionOptions.vue';
import useViewRestriction from '@component-library/composables/useViewRestriction';

/**
 * Native in vue 3, remove this dependency later
 */
import Teleport from 'vue2-teleport';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';

export default {
  props: [
    'disabled',
    'project',
    'showTabSettings',
    'tab',
    'tabs',
    'shouldSave',
    'onFieldUpdated',
    'onFieldDeleted',
  ],
  data: () => ({
    sections: [],
    sectionIndex: null,
    fieldIndex: null,
    showTemplatePreviewModal: false,
    showExpressionEditorModal: false,
    mobileSize: false,
    sectionCheckDebounce: null,
    showHSCategoryModal: false,
    isLoadingSections: true,
    loadingProgress: 0,
    showFieldModal: false,
  }),
  components: {
    Draggable,
    TemplateSectionDraggable,
    TemplateFieldOptions,
    GpsPointMetadataSectionFieldOptions,
    TemplateSectionOptions,
    GpsPointMetadataSectionOptions,
    TemplateAvailableDraggables,
    TemplatePreviewModal,
    ExpressionEditorModal,
    CategorySelectorModal,
    PublicFormOptions,
    ProgressBar,
    Drawer,
    Teleport,
    Modal,
    InputCheckbox,
  },
  computed: {
    selectedSection() {
      return this.sectionIndex !== null
        ? this.sections[this.sectionIndex]
        : null;
    },
    selectedField() {
      return this.selectedSection && this.fieldIndex !== null
        ? this.selectedSection.template_fields[this.fieldIndex]
        : null;
    },
    existingSections() {
      return this.sections.filter((aSection) => !!aSection?.template_fields);
    },
    getNumberOfFields() {
      return this.sections
        .map((s) => s.template_fields.length)
        .reduce((a, b) => a + b, 0);
    },
    firstSectionInPublicForm() {
      return this.sections.find((item) => checkIsInPublicForm(item));
    },
    hasPublicLink() {
      return !!this.tab?.public_link;
    },
    templateFields() {
      return this.sections?.template_fields || [];
    },
    hasHSSection() {
      return this.tab.sections.findIndex((s) => s.is_health_safety) != -1;
    },
    // When a section or field is being added, updated or deleted, in order to keep
    // the orders consistent, users are not allowed to
    isTemplateEditorOperating() {
      return !!this.sections.find(
        (item) =>
          !item.id ||
          item.isUpdating ||
          item.isDeleting ||
          item.isDuplicating ||
          !!item.template_fields.find(
            (item2) => !item2.id || item2.isUpdating || item2.isDeleting
          )
      );
    },
    sectionsDraggableGroup() {
      return {
        name: 'sections',
        pull: false,
        put: (to, from) => {
          return (
            from.options.group.name === 'sections' &&
            to.options.group.name === 'sections' &&
            !this.isTemplateEditorOperating
          );
        },
      };
    },
  },
  watch: {
    async firstSectionInPublicForm(newValue, oldValue) {
      if (newValue?.id && newValue.id !== oldValue?.id) {
        newValue.is_shown_on_new_page = false;
        await newValue.save();
      }
    },
  },
  methods: {
    updateTemplateSections() {
      this.$emit('updateTemplateSections', this.sections);
    },
    updateExpression(expression) {
      this.updateFieldOptions({
        options: {
          ...this.sections[this.sectionIndex].template_fields[this.fieldIndex]
            .options,
          expression,
        },
      });
    },
    addMobileSections() {
      this.sectionIndex = null;
      this.showFieldModal = true;
      this.$refs.drawer?.close();
    },
    gotoMap() {
      this.$emit('goMap');
    },
    save() {
      this.$emit('save');
    },
    async createField({ sectionIndex, fieldIndex, value }) {
      const section = this.sections[sectionIndex];

      if (
        value.field_type_id === FieldTypeIds.COPY_DATA_LINK &&
        section.template_fields.some(
          (f) => f.field_type_id === FieldTypeIds.COPY_DATA_LINK
        )
      ) {
        this.$toastStore.error(
          'A section can have only one Copy Data Link field.'
        );
        return;
      }

      for (let i = fieldIndex; i < section.template_fields.length; i++) {
        const field = section.template_fields[i];
        section.setField(i, { ...field, order: i + 1 });
      }

      section.addField(fieldIndex, { ...value, order: fieldIndex });
      if (this.mobileSize) {
        this.showFieldModal = false;
      }
      await section.template_fields[fieldIndex].save();
      this.updateTemplateSections();
    },
    async updateField({ sectionIndex, fieldIndex, value }) {
      const section = this.sections[sectionIndex];
      const originalSection = this.sections.find(
        (item) => item.id === value.template_section_id
      );
      const isSectionChanged = section !== originalSection;
      const field = !isSectionChanged
        ? section.template_fields[fieldIndex]
        : originalSection.template_fields.find((item) => item.id === value.id);

      if (isSectionChanged && field.is_permanent) {
        this.$toastStore.error(
          `The field '${field.label}' should always be in the '${originalSection.label}' section.`
        );
        return;
      }

      const isWaitingForId = field && !field.id;
      if (isWaitingForId) {
        await new Promise((resolve) => {
          let i = 0;
          const intervalId = setInterval(() => {
            i++;
            if (field.id) {
              clearInterval(intervalId);
              resolve();
            } else if (i > 100) {
              clearInterval(intervalId);
              throw new Error('updateField: Failed to wait for field id');
            }
          }, 100);
        });
      }

      let newField;
      if (!isSectionChanged) {
        const order = value.order ?? fieldIndex;
        if (order !== fieldIndex) {
          section.moveField(fieldIndex, order);
        } else {
          section.setField(order, {
            ...field,
            ..._omit(value, 'id'),
            order,
          });
        }
        newField = section.template_fields[order];
        await newField.save();
        this.updateTemplateSections();
      } else {
        for (let i = fieldIndex; i < section.template_fields.length; i++) {
          const field = section.template_fields[i];
          section.setField(i, { ...field, order: i + 1 });
        }

        const { order: originalFieldIndex } = field;
        for (
          let i = originalFieldIndex + 1;
          i < originalSection.template_fields.length;
          i++
        ) {
          const field = originalSection.template_fields[i];
          originalSection.setField(i, { ...field, order: i - 1 });
        }
        const nextField = {
          ...field,
          ..._omit(value, 'id'),
          order: fieldIndex,
          template_section_id: section.id,
        };
        originalSection.deleteField(originalFieldIndex);
        section.addField(fieldIndex, nextField);
        newField = section.template_fields[fieldIndex];
        await newField.save();
        this.updateTemplateSections();
      }
      const app = findAppByFieldId(this.tabs, newField.id);
      this.onFieldUpdated(newField, app);
    },
    addCondition({ sectionIndex, fieldIndex, condition }) {
      const options = {
        ...this.sections[sectionIndex].template_fields[fieldIndex].options,
      };
      options.conditions = [...options.conditions, condition];
      this.updateFieldOptions({ sectionIndex, fieldIndex, options });
    },
    async updateFieldOptions({
      sectionIndex = this.sectionIndex,
      fieldIndex = this.fieldIndex,
      options = null,
    }) {
      if (!options) {
        return;
      }

      const value = {
        ...this.sections[sectionIndex].template_fields[fieldIndex],
        options,
      };
      await this.updateField({ sectionIndex, fieldIndex, value });
    },
    updateFieldLabel: _debounce(async function ({ index, fieldIndex, label }) {
      const value = {
        ...this.sections[index].template_fields[fieldIndex],
        label,
      };
      await this.updateField({ sectionIndex: index, fieldIndex, value });
    }, 600),
    updateSection: _debounce(async function (value) {
      // Prefer ID as sectionIndex may change
      // ID may be undefined/null before first save, fallback to selected this.sectionIndex.
      let sectionIndex = value?.id
        ? this.sections.findIndex((item) => item.id === value.id)
        : this.sectionIndex;
      if (sectionIndex === undefined) {
        sectionIndex = this.sectionIndex;
      }
      let section = this.sections[sectionIndex];

      section = {
        ...section,
        ...value,
        order: sectionIndex,
        template_tab_id: this.tab.id,
      };
      if (!checkIsInPublicForm(section)) {
        section = { ...section, is_shown_on_new_page: false };
      }
      section = Section.wrap(section);

      const isWaitingForId = section && !section.id;
      if (isWaitingForId) {
        await new Promise((resolve) => {
          let i = 0;
          const intervalId = setInterval(() => {
            i++;
            if (section.id) {
              clearInterval(intervalId);
              resolve();
            } else if (i > 100) {
              clearInterval(intervalId);
              throw new Error('updateSection: Failed to wait for section id');
            }
          }, 100);
        });
      }

      // Section index may change as a result of await.
      sectionIndex =
        this.sections.findIndex((item) => item.id === section.id) ||
        sectionIndex;
      this.sections.splice(sectionIndex, 1, section);
      await section.save();
      this.updateTemplateSections();
    }, 600),
    hasFields(section) {
      return !!section?.template_fields;
    },
    showEditor() {
      this.showExpressionEditorModal = true;
    },
    openGatherSupportPage() {
      window.open(DATANEST_URL + '/support/gather', '_blank');
    },
    selectSection(index) {
      if (this.disabled || this.sectionIndex == index) {
        return;
      }

      this.$root.$emit('closeTemplateOptions');

      this.sectionIndex = index;
    },
    async removeSection(index) {
      try {
        let section = this.sections[index];
        if (!(section instanceof Section)) {
          section = Section.wrap(section);
        }
        await section.delete();
        this.sections.splice(index, 1);
        this.sections.forEach((item, itemIndex) => {
          item.order = itemIndex;
        });
        this.updateTemplateSections();
        this.sectionIndex = null;
        this.fieldIndex = null;
      } catch (e) {
        this.$toastStore.error(
          'Failed to delete section, please refresh and try again'
        );
        throw e;
      }
    },
    async duplicateSection(index) {
      const section = this.sections[index];
      const order = this.sections.length;
      const duplicate = await section.duplicate(order);

      this.sections = [...this.sections, duplicate];
      this.updateTemplateSections();
      this.selectSection(order);
    },
    resizeHandler() {
      this.mobileSize = window.innerWidth < 991;
    },
    handleDeleteField({ field, app }) {
      this.fieldIndex = null;
      this.updateTemplateSections();
      this.onFieldDeleted(field, app);
    },
    async handleSectionsChange({ added, moved }) {
      if (added) {
        const { element } = added;

        if (element.is_gps_point_metadata) {
          if (this.tab.drawing_type !== 'point') {
            this.$toastStore.info(
              'The section can be only added to an app with a Point collection type.'
            );
            return;
          }

          if (this.sections.find((item) => item.is_gps_point_metadata)) {
            this.$toastStore.info(
              'The section has already existed in the app.'
            );
            return;
          }
        }

        // When sections is empty, the newIndex is 1 because of the placeholder
        // in the draggable component. It needs to be corrected to 0.
        const newIndex = this.sections.length ? added.newIndex : 0;

        for (let i = newIndex; i < this.sections.length; i++) {
          const section = this.sections[i];
          section.order = i + 1;
        }

        await this.addSection({
          element,
          index: newIndex,
        });
      } else if (moved) {
        const { element, oldIndex, newIndex } = moved;
        const nextSection = Section.wrap({
          ...element,
          order: newIndex,
        });
        // Swap positions
        const sectionAtOrder = this.sections[nextSection.order];
        this.sections.splice(nextSection.order, 1, nextSection);
        this.sections.splice(
          oldIndex,
          1,
          Section.wrap({
            ...sectionAtOrder,
            order: oldIndex,
          })
        );
        await nextSection.save();
        this.updateTemplateSections();
      }
    },
    async addSection({ element, index }) {
      const section = Section.wrap({
        ...element,
        template_tab_id: this.tab.id,
        order: index,
      });

      this.sections.splice(index, 0, section);
      this.fieldIndex = null;
      this.selectSection(index);
      if (this.mobileSize) {
        this.showFieldModal = false;
      }
      await section.save();
      this.updateTemplateSections();
    },
  },
  created() {
    this.resizeHandler();
  },
  async mounted() {
    const access = useViewRestriction(AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR);
    if (!access.hasPermissionToAccess()) {
      this.$toastStore.error('You do not have permission to access this page');
      this.gotoMap();
      return;
    }

    if (this.tab.id) {
      this.isLoadingSections = true;
      this.sections = await Section.getTabSections(this.tab.id, (progress) => {
        this.loadingProgress = progress;
      }).catch((err) => {
        this.isLoadingSections = false;
        throw err;
      });
      this.isLoadingSections = false;
    }
    this.$root.$on('selectSection', (section) => {
      this.selectSection(section);

      this.$emit('finishEditingTab');
    });

    this.$root.$on('clearSection', () => {
      this.sectionIndex = null;
    });

    this.$root.$on('showExpressionEditor', () => {
      this.showExpressionEditorModal = true;
    });

    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    this.$root.$off('selectSection');
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
};
</script>
