<template>
  <responsive-or-slide-up
    title="Grouping"
    v-model="showGroupOptions"
    data-cy="field-options"
    :mobileSize="mobileSize"
  >
    <template #body>
      <div class="d-flex flex-row align-items-center mb-3">
        <div class="my-auto me-auto">
          Create a group which contains some of the above options, when this
          group is linked to a condition, then only the options listed within
          the group will be displayed
          <i
            class="fas fa-info-circle"
            tabindex="0"
            data-bs-toggle="tooltip"
            title="
If the options are 1,2,3,4,5, and 6; you can create an 'odd' and 'even' group.
After this you would attach the group to a condition e.g. A text field which you type 'odd' into
When the condition is not met, then all options will be displayed in the dropdown
If the condition is met then only all the options within the 'odd' group would be displayed
            "
          />
        </div>
        <button
          type="button"
          class="btn btn-sm ms-2"
          :class="{
            'btn-outline-primary': !emptyGroup,
            'btn-dark': emptyGroup,
          }"
          :disabled="emptyGroup"
          @click="addGroup"
        >
          <i class="fal fa-plus m-1" />
        </button>
      </div>
      <template v-for="(group, key) of options.groups">
        <field-group-item
          :value="options"
          @input="updateOptions"
          @remove="removeGroup"
          :index="key"
          :key="key"
        />
      </template>
      <template v-if="false">
        <div class="alert alert-info" role="alert">
          No groups have been created
        </div>
      </template>
      <div v-if="groupExists" class="flex-fill my-auto fw-bold mt-3">
        Group Conditions
      </div>
      <div v-if="groupExists" class="d-flex flex-row align-items-center mb-3">
        <label class="form-label me-auto mb-0">
          Attach the group to a condition
        </label>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          @click="addCondition"
        >
          <i class="fal fa-plus m-1" />
        </button>
      </div>
      <FieldConditionList
        v-if="groupExists"
        v-model="conditions"
        :fields="fields"
        :isDefault="true"
        :groups="groups"
        @removeCondition="removeCondition"
      /> </template
  ></responsive-or-slide-up>
</template>

<script>
import FieldGroupItem from './FieldGroupItem.vue';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import FieldConditionList from './FieldConditionList.vue';

export default {
  props: {
    options: {
      required: true,
    },
    fields: {
      required: true,
    },
    mobileSize: {
      required: true,
    },
  },
  components: {
    FieldGroupItem,
    ResponsiveOrSlideUp,
    FieldConditionList,
  },
  data: () => ({
    showGroupOptions: false,
  }),
  mounted() {
    if (this.groupExists) this.showGroupOptions = true;
  },
  methods: {
    addGroup() {
      const { groups } = this.options;
      this.groups = [
        ...groups,
        {
          items: [],
          default: null,
        },
      ];
    },
    updateOptions(options) {
      this.$emit('updateOptions', options);
    },
    removeGroup(index) {
      this.groups = this.groups.filter(
        (item, itemIndex) => itemIndex !== index
      );
    },
    addCondition() {
      this.conditions = [
        ...this.conditions,
        {
          field_id: this.fields[0].id,
          operator: '=',
          value: null,
          default: null,
        },
      ];
    },
    removeCondition(index) {
      this.conditions = this.conditions.filter(
        (item, itemIndex) => itemIndex !== index
      );
    },
  },
  computed: {
    groupExists() {
      const groups = this.options.groups;
      return groups.find((aGroup) => aGroup?.items.length >= 1);
    },
    conditions: {
      get() {
        return this.options.groupConditions;
      },
      set(groupConditions) {
        this.$emit('updateOptions', { ...this.options, groupConditions });
      },
    },
    emptyGroup() {
      return this.options.groups.find((aGroup) => aGroup?.items?.length == 0);
    },
    groups: {
      get() {
        return this.options.groups;
      },
      set(groups) {
        this.$emit('updateOptions', { ...this.options, groups });
      },
    },
  },
};
</script>
