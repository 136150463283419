<template>
  <div
    v-if="value"
    class="list-group-item list-group-item-action rounded-0 field d-flex align-items-center justify-content-between"
    :class="{
      active: selected,
      'field-draggable': !isLabelInputFocused && canEdit,
    }"
    :style="disabled ? '' : 'cursor: grab'"
    data-cy="field-draggable"
    @click.stop="selectField"
  >
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center draggable-field">
        <div class="draggable-layer">
          <div class="draggable-control"></div>
        </div>
        <span class="icon-width">
          <FieldIcon
            v-show="value.id && !value.isUpdating && !value.isDeleting"
            :icon="Object.keys(getFieldIcon)[0]"
            class="mb-0 h5 fw-light"
          />

          <Spinner v-if="!value.id || value.isUpdating || value.isDeleting" />
        </span>

        <InfoButton
          v-if="invalidNameError"
          class="me-2"
          :danger="true"
          backgroundColor="#FF0000"
          :info="invalidNameError"
        />

        <span
          class="mb-0 field-label"
          :class="{ 'text-danger': invalidNameError }"
          v-show="!isLabelEditable"
          v-tooltip="invalidNameError"
        >
          {{ label }}
        </span>

        <input
          v-show="isLabelEditable"
          type="text"
          v-tooltip="invalidNameError"
          class="border-0 bg-transparent mb-0 w-100"
          :class="{ 'text-danger is-invalid': invalidNameError }"
          v-model="label"
          @keyup.enter="$root.$emit('clearSection')"
          @focus="handleLabelInputFocus"
          @blur="handleLabelInputBlur"
          ref="field-label"
          placeholder="Name field here"
        />
      </div>
      <div class="d-flex align-items-center gap-1">
        <div class="d-flex align-items-center gap-1">
          <span
            v-if="value.id && section.primary_field_id == value.id"
            class="badge bg-primary"
          >
            Is Primary
          </span>
          <span
            v-if="value.id && section.secondary_field_id == value.id"
            class="badge bg-success"
          >
            Is Secondary
          </span>
          <span
            class="badge bg-danger"
            v-if="
              hasPublic &&
              !section.is_public_form &&
              value.options.is_public_form
            "
          >
            <i class="fas fa-globe-asia"></i>
            Included on Public Form
          </span>
          <span v-if="conditionCheck" class="badge bg-info">
            Is Conditional
          </span>
          <span
            v-if="toleranceExplainer"
            class="badge bg-warning"
            :title="toleranceExplainer"
          >
            Tolerance Check</span
          >
          <span class="badge bg-secondary">{{ badge }}</span>
        </div>

        <div v-if="selected && !value.is_permanent && canEdit">
          <div v-if="showDeleteConfirmation" class="btn-group btn-group-sm">
            <button
              class="btn btn-outline-secondary"
              @click="() => (showDeleteConfirmation = false)"
            >
              <i class="fas fa-times" />
            </button>
            <button
              class="btn btn-outline-danger"
              :disabled="isTemplateEditorOperating"
              @click="$emit('delete')"
            >
              <i class="fas fa-check" />
            </button>
          </div>
          <button
            v-else
            data-cy="field-remove"
            @click="() => (showDeleteConfirmation = true)"
            class="btn btn-outline-danger btn-sm ms-2"
            :disabled="isTemplateEditorOperating"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFieldTypeById,
  getFieldIconById,
  FieldTypeIds,
} from '@component-library/fields';
import { buildSentenceFromField } from '@component-library/utils/tolerance';
import FieldIcon from './FieldIcon.vue';
import sectionTemplates from '../../helpers/section-templates';
import InfoButton from '@component-library/components/InfoButton.vue';
import { getFieldReferenceError } from '@component-library/gather';
import Spinner from '@component-library/components/Spinner.vue';

export default {
  components: {
    FieldIcon,
    InfoButton,
    Spinner,
  },
  data: () => ({
    showDeleteConfirmation: false,
    isLabelInputFocused: false,
    labelValue: null,
  }),
  props: {
    value: { required: true },
    index: { required: true },
    section: Object,
    tab: Object,
    selected: { default: false },
    disabled: { default: false },
    isTemplateEditorOperating: Boolean,
  },
  watch: {
    'value.label'() {
      if (!this.isLabelInputFocused) {
        this.labelValue = this.value.label;
      }
    },
  },
  computed: {
    field() {
      return this.value;
    },
    label: {
      get() {
        return this.labelValue;
      },
      set(updated) {
        this.labelValue = updated;
        const label = updated.trim();
        if (!label) {
          return;
        }

        this.$emit('updateLabel', { index: this.index, label });
      },
    },
    invalidNameError() {
      return getFieldReferenceError(this.value, this.section);
    },
    hasPublic() {
      return this.tab?.public_link;
    },
    conditionCheck() {
      return (
        this.value?.options?.conditions &&
        this.value.options.conditions.length > 0
      );
    },
    canEdit() {
      return !this.value.options?.disabled;
    },
    badge() {
      const fieldType = getFieldTypeById(this.value.field_type_id);

      if (fieldType) {
        return fieldType.short_name || fieldType.display_name;
      }

      return 'Unknown';
    },
    getFieldIcon() {
      return getFieldIconById(this.value.field_type_id);
    },
    toleranceExplainer() {
      if (this.value.field_type_id != FieldTypeIds.NUMBER) {
        return false;
      }
      return buildSentenceFromField(this.value);
    },
    isLabelEditable() {
      if (!this.selected || !this.canEdit) {
        return false;
      }

      if (!this.section.is_gps_point_metadata) {
        return true;
      }

      return !sectionTemplates
        .getTemplateById('gps_point_metadata')
        .fields.map((item) => item.system_reference)
        .includes(this.field.system_reference);
    },
  },
  methods: {
    selectField() {
      if (this.disabled || !this.field.id) {
        return;
      }
      this.$emit('selectField', this.index);
    },
    handleLabelInputFocus() {
      if (this.canEdit) {
        this.isLabelInputFocused = true;
      }
    },
    handleLabelInputBlur() {
      this.isLabelInputFocused = false;
    },
  },
  mounted() {
    this.labelValue = this.value.label;
    if (this.isLabelEditable) {
      const labelInput = this.$refs['field-label'];
      labelInput.focus();
      labelInput.select();
    }
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  height: 46px;
  padding-left: 0px;
}

.list-group-item:hover .draggable-control {
  visibility: visible;
}

.list-group-item.active {
  background: #f9f9f9;
  border-color: rgba(0, 0, 0, 0.125);

  i.h5,
  span.field-label {
    color: #212529;
  }
}

.field-label {
  height: 19px;
  overflow: hidden;
}

.icon-width {
  min-width: 3em;
  max-width: 3em;
}
</style>
