import {
  Feature,
  Geolocation,
  MapBrowserEvent,
  Overlay,
  View,
  Map as ol_Map,
} from 'ol';
import Popup from 'ol-ext/overlay/Popup';
import BaseObject from 'ol/Object';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import type { Coordinate } from 'ol/coordinate';
import { Geometry } from 'ol/geom';
import type { DefaultsOptions as DefaultInteractionsOptions } from 'ol/interaction';
import { defaults as defaultInteractions } from 'ol/interaction';
import type { ProjectionLike } from 'ol/proj';
import LoadManager from './common/LoadManager';
import {
  checkEqualityOfTwoCoordinatesLeniently,
  checkIsValidLatLng,
  fromLonLat,
  legacyLatLngToLonLat,
  lonLatToLegacyLatLng,
  toLonLat,
} from './common/coordinate';
import { throwError } from './common/error';
import { fromGeoJSON, toGeoJSON } from './common/geojson';
import {
  GeolocationResult,
  formatGeolocationResult,
} from './common/geolocation';
import type { StoreApi } from './common/store-api';
import { getProjectUnit, getStoreApi, setStoreApi } from './common/store-api';
import type { Id, LegacyLatLng, LonLat } from './common/types';
import type { FitTarget } from './common/view';
import MeasureControl from './control/MeasureControl';
// import SimulateControl from './control/SimulateControl';
import { produce } from 'immer';
import {
  containsCoordinate,
  getCenter,
  getCenter as getExtentCenter,
} from 'ol/extent';
import {
  equivalent,
  getPointResolution,
  get as getProjection,
  transform,
  transformExtent,
} from 'ol/proj';
import { Code, OlbmError, getMessage } from './common/error';
import { getAddressFromLonLat } from './common/geolocation';
import { MapType } from './common/types';
import GoogleLogoControl from './control/GoogleLogoControl';
import { getAllSampleIdsFromSample } from './evalu8/exceedance';
import { checkIsSpecificChemicalPlan } from './evalu8/utils';
import type {
  FeatureClickEventPayload,
  GeolocationChangeEventPayload,
} from './event/types';
import { Event } from './event/types';
import { getFigureStylingRulesOnLayer } from './figure/styling-rule';
import InteractionManager from './interaction/InteractionManager';
import LayerManager from './layer/LayerManager';
import * as basemapConfig from './layer/basemap/config';
import createBasemapServiceLayer from './layer/basemap/createBasemapServiceLayer';
import { BasemapId, BasemapXyzApi } from './layer/basemap/types';
import { findBasemapApiById, inspectBasemap } from './layer/basemap/utils';
import getLayerModelIcon from './layer/getLayerModelIcon';
import getSampleStyle, {
  deleteSampleStyleFromCache,
} from './layer/sample/getSampleStyle';
import type { PoiAtPosition, Sample, SampleStyle } from './layer/sample/types';
import {
  checkIsFilteredOutBySampleGroup,
  checkIsSampleGroup,
  getSampleTitle,
} from './layer/sample/utils';
import requestFeatureServerLayerRenderer from './layer/service/esri/requestFeatureServerLayerRenderer';
import requestImageServerLayerRenderer from './layer/service/esri/requestImageServerLayerRenderer';
import requestMapServerLayerRenderers from './layer/service/esri/requestMapServerLayerRenderers';
import {
  checkIsFeatureServerFolderLayerModel,
  checkIsImageServerFolderLayerModel,
  checkIsMapServerFolderLayerModel,
  checkIsVectorTileServerFolderLayerModel,
} from './layer/service/esri/utils';
import type { Integration } from './layer/service/integration';
import {
  IntegrationId,
  findIntegrationById,
} from './layer/service/integration';
import {
  checkIsWfsFolderLayerModel,
  checkIsWmsFolderLayerModel,
  checkIsWmtsFolderLayerModel,
} from './layer/service/ogc-opengis/utils';
import getShapeStyle from './layer/shape/getShapeStyle';
import type { ShapeLayer, SingleShapeLayerModel } from './layer/shape/types';
import {
  checkIsBufferLayerModel,
  checkIsSingleShapeLayerModel,
} from './layer/shape/utils';
import type { Layer, LayerModel } from './layer/types';
import { LayerType } from './layer/types';
import {
  checkIsPlainFolderLayerModel,
  checkIsTempLayerModel,
  getMaxZoom,
  getModel,
  getModelId,
  getType,
} from './layer/utils';
import { setLayoutZoom } from './measurement/layout';
import { setDpi } from './measurement/medium';
import type { Pixel, Size } from './measurement/types';
import { Dpi } from './measurement/types';
import { checkIsImperial } from './measurement/unit';
import './projection';
import { FillStyle } from './style/types';

export type MapOptions = {
  type: MapType;
  target: HTMLDivElement;
  projection: ProjectionLike;
  center: LonLat;
  zoom: number;
  basemapId: BasemapId | null;
  storeApi: StoreApi;
  hedgeImage: HTMLImageElement;
  interactions?: DefaultInteractionsOptions;
  overlays?: Overlay[];
};

const HIT_TOLERANCE = 1e1;

// A facade to the OpenLayers map
export default class Map extends BaseObject {
  static POPUP_ID = 'popup_overlay';
  static GEOLOCATION_OVERLAY_ID = 'geolocation_overlay';

  map: ol_Map;
  layerManager: LayerManager;
  interactionManager: InteractionManager;
  scaleLine: ScaleLine | undefined;
  geolocation: Geolocation | undefined;
  geolocationResult: GeolocationResult | undefined;
  // Used to detect whether the click event is a single click or a double click event.
  // It is used in the handleClick method.
  // Inspired by https://stackoverflow.com/questions/5497073/how-to-differentiate-single-click-event-and-double-click-event
  clicks: number = 0;
  googleLogoControl = new GoogleLogoControl({ layoutZoom: 1 });

  constructor(options: MapOptions) {
    super({});
    const {
      type,
      target,
      projection,
      center,
      zoom,
      basemapId,
      storeApi,
      hedgeImage,
      interactions,
      overlays,
    } = options;
    this.map = new ol_Map({
      controls: defaultControls({
        attribution: true,
        attributionOptions: {
          collapsed: true,
          collapsible: true,
        },
        rotate: true,
        zoom: true,
      }).extend([
        new MeasureControl(),
        // Used for debugging the tracking feature
        // new SimulateControl(() => this.geolocation!),
      ]),
      interactions: defaultInteractions(interactions),
      overlays,
      target: target,
      view: new View({
        projection,
        center: fromLonLat(center, projection),
        zoom,
      }),
    });
    this.map.on('pointermove', this.handlePointerMove.bind(this));
    this.map.on('click', this.handleClick.bind(this));

    this.map.set('type', type);
    setDpi(this.map, Dpi.Screen);
    setLayoutZoom(this.map, 1);
    setStoreApi(this.map, storeApi);
    this.map.set('hedgeImage', hedgeImage);

    this.layerManager = new LayerManager(this.map);
    this.interactionManager = new InteractionManager(this.layerManager);
    this.map.set('interactionManager', this.interactionManager);
    if (basemapId !== null) {
      this.useBasemapApi(basemapId);
    }
  }
  useBasemapApi(basemapId: BasemapId): Layer | undefined {
    const storeApi = getStoreApi(this.map);
    const basemapApi =
      findBasemapApiById(basemapId) ?? basemapConfig.OPENSTREETMAP_API;
    try {
      const layer = createBasemapServiceLayer(
        this.map,
        basemapApi,
        (basemapApi) => {
          if (
            ![BasemapId.NEARMAP, BasemapId.METROMAP].includes(basemapApi.id)
          ) {
            return basemapApi;
          }

          inspectBasemap(this.map, basemapApi.id);

          const integrations = storeApi.getIntegrations();
          let integration: Integration | undefined;
          switch (basemapApi.id) {
            case BasemapId.NEARMAP:
              integration = findIntegrationById(
                integrations,
                IntegrationId.NEARMAP
              );
              break;
            case BasemapId.METROMAP:
              integration = findIntegrationById(
                integrations,
                IntegrationId.METROMAP
              );
              break;
          }

          return produce(basemapApi as BasemapXyzApi, (draft) => {
            const { url } = draft.layerModel.geojson.properties.service;
            draft.layerModel.geojson.properties.service.url = url.replace(
              '{API_KEY}',
              integration!.apiKey!
            );
          });
        }
      );
      this.layerManager.addLayer(layer);

      if (
        [
          BasemapId.GOOGLE_MAPS_ROADMAP,
          BasemapId.GOOGLE_MAPS_SATELLITE,
        ].includes(basemapApi.id)
      ) {
        this.map.addControl(this.googleLogoControl);
      } else {
        this.map.removeControl(this.googleLogoControl);
      }

      return layer;
    } catch (error) {
      this.layerManager.clearBasemapLayers();
      storeApi.showError(
        error instanceof OlbmError ? error.message : getMessage(Code.Generic)
      );
    }
  }
  getManagedFeaturesAtPixel(pixel: Pixel) {
    const result: Feature<Geometry>[] = [];
    const coord = this.map.getCoordinateFromPixel(pixel);
    const storeApi = getStoreApi(this.map);

    const featuresAtPixel = this.map.getFeaturesAtPixel(pixel, {
      layerFilter: (candidateLayer) => {
        const type = getType(candidateLayer);
        if (
          !type ||
          [
            LayerType.SERVICE,
            LayerType.BUFFER,
            LayerType.BASEMAP_SERVICE,
          ].includes(type)
        ) {
          return false;
        }

        const model = getModel(storeApi, candidateLayer)!;
        const isBoundaryLayer =
          getType(candidateLayer) === LayerType.SITE_BOUNDARY;
        const ss = checkIsSingleShapeLayerModel(model)
          ? this.getShapeStyle(model)
          : undefined;
        const isNoOverlayUsed = ss?.fillStyle === FillStyle.Transparent;
        if (isBoundaryLayer || isNoOverlayUsed) {
          // The interior of a boundary or a shape which uses the No Overlay
          // fill style is not clickable
          const geom = (candidateLayer as ShapeLayer)
            .getFirstFeature()!
            .getGeometry()!
            .clone();
          // Scale down the geometry a little bit in order to make the geometry
          // selectable by clicking inside the it near its outline.
          geom.scale(0.95);
          return !geom.intersectsCoordinate(coord);
        }

        return true;
      },
      hitTolerance: HIT_TOLERANCE,
    }) as Feature<Geometry>[];
    result.push(...featuresAtPixel);

    // Sort managed features according to the order of their corresponding layers.
    // So that the topmost feature can be selected.
    result.sort((f1, f2) => {
      const layer1 = this.layerManager.findLayerByFeature(f1);
      const layer2 = this.layerManager.findLayerByFeature(f2);
      const layer1ZIndex = this.layerManager.getLayerZIndex(layer1);
      const layer2ZIndex = this.layerManager.getLayerZIndex(layer2);
      return layer2ZIndex - layer1ZIndex;
    });

    return result;
  }
  private findPoiAtPosition(
    layerModelId: Id,
    position: Coordinate
  ): PoiAtPosition | undefined {
    const storeApi = getStoreApi(this.map);
    const polySample = storeApi.findPolySampleByLayerModelId(layerModelId);
    const proj = this.map.getView().getProjection();

    if (
      polySample?.points_of_interest &&
      polySample.points_of_interest.length > 0
    ) {
      for (let i = 0; i < polySample.points_of_interest.length; i++) {
        const poi = polySample.points_of_interest[i];
        const poiCoord = fromLonLat(
          { longitude: poi.longitude, latitude: poi.latitude },
          proj
        );
        if (
          checkEqualityOfTwoCoordinatesLeniently(
            this.map,
            position,
            poiCoord,
            20
          )
        ) {
          return {
            sampleId: polySample.id as number,
            index: i,
            layer: this.layerManager.findLayerByModelId(layerModelId),
            position,
          };
        }
      }
    }
    return undefined;
  }
  private handlePointerMove(evt: MapBrowserEvent<any>) {
    const targetElement = this.map.getTargetElement();

    const hoveredPoi = this.map.get('hoveredPoi') as PoiAtPosition;
    if (hoveredPoi) {
      this.map.set('hoveredPoi', null);
      hoveredPoi.layer.refresh();
    }

    if (this.interactionManager.isDrawing()) {
      targetElement.style.cursor = 'pointer';
      return;
    }

    const [managedFeature] = this.getManagedFeaturesAtPixel(evt.pixel as Pixel);
    if (!managedFeature) {
      targetElement.style.cursor = 'grab';
      return;
    }

    const layer = this.layerManager.findLayerByFeature(managedFeature);
    // layer could be null when the managedFeature is from a vector tile layer.
    // The Transform used by the layer which is being edited manages cursors.
    if (
      layer &&
      this.layerManager.checkIsEditableLayer(layer) &&
      !this.interactionManager.isLayerBeingEdited(layer)
    ) {
      targetElement.style.cursor = 'pointer';

      // Record the point of interest on which the pointer hovers
      const layerModelId = getModelId(layer);
      const poi = this.findPoiAtPosition(layerModelId, evt.coordinate);
      if (poi) {
        this.map.set('hoveredPoi', poi);
        poi.layer.refresh();
      }

      return;
    }

    if (this.interactionManager.checkIsPolyEditWorking()) {
      targetElement.style.cursor = 'pointer';
      return;
    }

    targetElement.style.cursor = 'grab';
  }
  private handleClick(event: MapBrowserEvent<any>) {
    this.clearClickedPoi();

    this.clicks++;
    if (this.clicks === 1) {
      setTimeout(() => {
        try {
          if (this.clicks === 1) {
            if (
              this.interactionManager.isDrawing() ||
              this.interactionManager.isEditing()
            ) {
              return;
            }

            const features = this.getManagedFeaturesAtPixel(
              event.pixel as Pixel
            );

            if (!features.length) {
              return;
            }

            const [feature] = features;

            const layer = this.layerManager.findLayerByFeature(feature);
            const layerModelId = getModelId(layer);
            const poi = this.findPoiAtPosition(layerModelId, event.coordinate);
            if (poi) {
              this.map.set('clickedPoi', poi);
            }

            this.dispatchEvent(
              new Event('feature-click', {
                feature,
                cursorPosition: event.coordinate,
              } as FeatureClickEventPayload)
            );
          }
        } finally {
          this.clicks = 0;
        }
      }, 300);
    }
  }
  animateView(center: LonLat | LegacyLatLng | Coordinate, zoom?: number) {
    const view = this.map.getView();
    const proj = view.getProjection();

    if (typeof center === 'object') {
      if ('longitude' in center && 'latitude' in center) {
        center = fromLonLat(center, proj);
      } else if ('lat' in center && 'lng' in center) {
        center = fromLonLat(legacyLatLngToLonLat(center), proj);
      }
    }

    const options: any = { center };
    if (zoom) {
      options.zoom = zoom;
    }

    this.map.getView().animate(options);
  }
  fit(target: FitTarget, options?: object) {
    this.map.getView().fit(target, options);
  }
  fromLegacyLatLng(latLng: LegacyLatLng): Coordinate {
    const proj = this.map.getView().getProjection();
    const lonLat = legacyLatLngToLonLat(latLng);
    return fromLonLat(lonLat, proj);
  }
  toLegacyLatLng(coord: Coordinate): LegacyLatLng {
    const proj = this.map.getView().getProjection();
    const lonLat = toLonLat(coord, proj);
    return lonLatToLegacyLatLng(lonLat);
  }
  getLayerModelIcon(model: LayerModel) {
    const storeApi = getStoreApi(this.map);
    const figure = storeApi.getSelectedFigure()!;
    return getLayerModelIcon(this.map, figure, model);
  }
  fromGeoJSON(object) {
    return fromGeoJSON(this.map, object);
  }
  toGeoJSON(object) {
    return toGeoJSON(this.map, object);
  }
  updateSize() {
    this.map.updateSize();
  }
  addScaleLine() {
    if (this.scaleLine) {
      return;
    }

    const unit = getProjectUnit(this.map);
    const isImperial = checkIsImperial(unit);

    this.scaleLine = new ScaleLine({
      units: isImperial ? 'imperial' : 'metric',
    });
    this.map.addControl(this.scaleLine);
  }
  removeScaleLine() {
    if (!this.scaleLine) {
      return;
    }

    this.map.removeControl(this.scaleLine);
    this.scaleLine = undefined;
  }
  enableGeolocation(
    trackingOptions = {
      maximumAge: 10000, // 10 secs
      enableHighAccuracy: true,
      timeout: 600000, // 60 mins
    }
  ) {
    const projection = this.map.getView().getProjection();
    const popup = this.map.getOverlayById(Map.POPUP_ID) as Popup;
    popup.on('change:position', async () => {
      const position = popup.getPosition();
      const { longitude, latitude } = toLonLat(position, projection);
      const address = await getAddressFromLonLat({ longitude, latitude });

      const addressContainerEl =
        popup.content.querySelector('.address-container');
      addressContainerEl.innerHTML = address
        ? `<b>${address.split(',').join('<br/>')}</b>`
        : '<b>No address found</b>';
    });

    let overlay = this.map.getOverlayById(Map.GEOLOCATION_OVERLAY_ID);
    if (!overlay) {
      const markerEl = document.createElement('img');
      markerEl.src = '/images/map_icons/geolocation_marker.png';
      markerEl.style.cursor = 'pointer';
      markerEl.addEventListener('click', () => {
        if (!this.geolocationResult) {
          this.dispatchEvent('geolocation-error');
          return;
        }

        popup.show(
          this.geolocationResult.position,
          formatGeolocationResult(this.geolocationResult, projection)
        );
      });
      overlay = new Overlay({
        id: Map.GEOLOCATION_OVERLAY_ID,
        positioning: 'center-center',
        element: markerEl,
        stopEvent: false,
      });
      this.map.addOverlay(overlay);
    }

    if (!this.geolocation) {
      this.geolocation = new Geolocation({
        projection,
        trackingOptions,
      });
      this.geolocation.on('change', () => {
        if (!this.geolocation) {
          this.dispatchEvent('geolocation-error');
          return;
        }

        const position = this.geolocation.getPosition();
        if (!position) {
          this.dispatchEvent('geolocation-error');
          return;
        }

        const accuracy = this.geolocation.getAccuracy();
        const heading = this.geolocation.getHeading() || 0;
        const speed = this.geolocation.getSpeed() || 0;
        this.geolocationResult = { position, accuracy, heading, speed };

        overlay.setPosition(position);
        if (popup.getVisible()) {
          popup.show(
            position,
            formatGeolocationResult(this.geolocationResult, projection)
          );
        }

        this.dispatchEvent(
          new Event<GeolocationChangeEventPayload>(
            'geolocation-change',
            this.geolocationResult
          )
        );
      });
      this.geolocation.on('error', () => {
        this.dispatchEvent('geolocation-error');
      });
      this.geolocation.setTracking(true);
    } else if (!this.geolocation.getTracking()) {
      this.geolocation.setTracking(true);
    }
    this.dispatchEvent('geolocation-enabled');
  }
  disableGeolocation(silent: boolean = false) {
    const popup = this.map.getOverlayById(Map.POPUP_ID) as Popup;
    popup.hide();

    const overlay = this.map.getOverlayById(Map.GEOLOCATION_OVERLAY_ID);
    if (overlay) {
      this.map.removeOverlay(overlay);
    }

    if (this.geolocation) {
      this.geolocation.setTracking(false);
      this.geolocation = undefined;
    }

    this.geolocationResult = undefined;

    !silent && this.dispatchEvent('geolocation-disabled');
  }
  getCenterLonLat(): LonLat {
    const view = this.map.getView();
    const center = view.getCenter();
    const proj = view.getProjection();
    return toLonLat(center!, proj);
  }
  getCurrentLocationLonLat(): LonLat | undefined {
    const position = this.geolocationResult?.position;
    return position
      ? toLonLat(position, this.map.getView().getProjection())
      : undefined;
  }
  getZoom() {
    return this.map.getView().getZoom();
  }
  getProjection() {
    return this.map.getView().getProjection();
  }
  setMinZoom(value: number) {
    return this.map.getView().setMinZoom(value);
  }
  getHoveredPoi(): PoiAtPosition | undefined {
    return this.map.get('hoveredPoi');
  }
  getClickedPoi(): PoiAtPosition | undefined {
    return this.map.get('clickedPoi');
  }
  clearClickedPoi() {
    this.map.set('clickedPoi', undefined);
  }
  /**
   * Make the geometry's center be at the perceived map center.
   * @param geom
   * @param occupiedBottomHeight bottom height which is occupied by the LayerStylingOffcanvas
   */
  centerGeometry(geom: Geometry, occupiedBottomHeight: number) {
    const geomExtent = geom.getExtent();
    const geomCenter = getCenter(geomExtent);
    const geomPixel = this.map.getPixelFromCoordinate(geomCenter);

    // The pixel of the perceived map center.
    const [mapWidth, mapHeight] = this.map.getSize() as Size;
    const perceivedMapPixel = [
      mapWidth / 2,
      (mapHeight - occupiedBottomHeight) / 2,
    ];
    const deltaX = geomPixel[0] - perceivedMapPixel[0];
    const deltaY = geomPixel[1] - perceivedMapPixel[1];

    let mapCenter = this.map.getView().getCenter()!;
    let mapPixel = this.map.getPixelFromCoordinate(mapCenter);
    mapPixel = [mapPixel[0] + deltaX, mapPixel[1] + deltaY];
    mapCenter = this.map.getCoordinateFromPixel(mapPixel);
    this.animateView(mapCenter);
  }
  getShapeStyle(layerModel: SingleShapeLayerModel) {
    const storeApi = getStoreApi(this.map);
    return getShapeStyle(this.map, storeApi.getSelectedFigure()!, layerModel);
  }
  getFigureStylingRulesOnLayer(layerModelId: Id) {
    const storeApi = getStoreApi(this.map);
    const figure = storeApi.getSelectedFigure();
    if (!figure) {
      return [];
    }

    return getFigureStylingRulesOnLayer(this.map, figure, layerModelId);
  }
  refreshLayersAffectedByStylingRules() {
    const storeApi = getStoreApi(this.map);
    const allSampleGroups = storeApi.findLayerModelsByType(
      LayerType.SAMPLE_GROUP
    );
    const stylableLayerModels = storeApi.getStylableLayerModels();
    for (const layerModel of [...allSampleGroups, ...stylableLayerModels]) {
      const fsrsOnLayer = this.getFigureStylingRulesOnLayer(layerModel.id);
      if (fsrsOnLayer.length) {
        const layer = this.layerManager.findLayerByModelId(layerModel.id);
        layer?.refresh();
      }
    }
  }
  deleteSampleStyleFromCache(sample: Sample) {
    const storeApi = getStoreApi(this.map);
    const selectedFigure = storeApi.getSelectedFigure();
    if (!selectedFigure) {
      return;
    }
    deleteSampleStyleFromCache(this.map, selectedFigure, sample);
  }
  checkIsFilteredOutBySampleGroup(sample: Sample) {
    return checkIsFilteredOutBySampleGroup(this.map, sample);
  }
  checkIsRenderableNonSpatialSampleGroup(layerModelId: Id): boolean {
    const storeApi = getStoreApi(this.map);
    return storeApi.checkIsRenderableNonSpatialSampleGroup(layerModelId);
  }
  changeProjection(newProjection: ProjectionLike) {
    const view = this.map.getView();
    const projection = view.getProjection();
    newProjection = getProjection(newProjection);

    if (equivalent(projection, newProjection)) {
      return;
    }

    const resolution = view.getResolution()!;
    const center = view.getCenter()!;
    const rotation = view.getRotation();
    const mpu = projection.getMetersPerUnit()!;
    const pointResolution =
      getPointResolution(projection, 1 / mpu, center, 'm') * mpu;

    let newCenter = transform(center, projection, newProjection);
    const newMpu = newProjection.getMetersPerUnit()!;
    const newPointResolution =
      getPointResolution(newProjection, 1 / newMpu, newCenter, 'm') * newMpu;
    const newResolution = (resolution * pointResolution) / newPointResolution;

    // Update the visible extent of service layers.
    const serviceLayers = this.layerManager.findLayersByTypes([
      LayerType.SERVICE,
      LayerType.BASEMAP_SERVICE,
    ]);
    for (let sl of serviceLayers) {
      const visibleExtent = sl.getExtent();
      if (visibleExtent) {
        const newVisibleExtent = transformExtent(
          visibleExtent,
          projection,
          newProjection,
          8
        );
        sl.setExtent(newVisibleExtent);
      }
    }

    const newExtent = newProjection.getExtent();
    if (!containsCoordinate(newExtent, newCenter)) {
      newCenter = getExtentCenter(newExtent);
    }
    const newView = new View({
      projection: newProjection,
      extent: newExtent,
      center: newCenter,
      resolution: newResolution,
      rotation,
    });
    this.map.setView(newView);
  }
  getSampleStyle(sample: Sample): SampleStyle | undefined {
    const storeApi = getStoreApi(this.map);
    const figure = storeApi.getSelectedFigure();
    return figure ? getSampleStyle(this.map, figure, sample) : undefined;
  }
}

export {
  Event,
  LoadManager,
  checkIsBufferLayerModel,
  checkIsFeatureServerFolderLayerModel,
  checkIsImageServerFolderLayerModel,
  checkIsMapServerFolderLayerModel,
  checkIsPlainFolderLayerModel,
  checkIsSampleGroup,
  checkIsSpecificChemicalPlan,
  checkIsTempLayerModel,
  checkIsValidLatLng,
  checkIsVectorTileServerFolderLayerModel,
  checkIsWfsFolderLayerModel,
  checkIsWmsFolderLayerModel,
  checkIsWmtsFolderLayerModel,
  findBasemapApiById,
  fromLonLat,
  getAllSampleIdsFromSample,
  getMaxZoom,
  getModelId,
  getSampleTitle,
  getType,
  legacyLatLngToLonLat,
  lonLatToLegacyLatLng,
  requestFeatureServerLayerRenderer,
  requestImageServerLayerRenderer,
  requestMapServerLayerRenderers,
  throwError,
  toLonLat,
};
