import _uniq from 'lodash/uniq';
import { Map } from 'ol';
import { CollectionType, DrawingType, Operator } from '../app/types';
import { getStoreApi } from '../common/store-api';
import type { Id } from '../common/types';
import type { SampleGroup, SampleStyle } from '../layer/sample/types';
import type { PolygonStyle, PolylineStyle } from '../layer/shape/style';
import { LayerType } from '../layer/types';
import type { Figure } from './types';
import { GatherField, InputValue } from '@component-library/gather';
import {
  evaluateValues, checkIsExpressionReady,
  resolveFieldFromValuePairs,
} from '@component-library/business-logic/expression';
import {
  Result,
  ACTION_CURRENT,
} from '@component-library/business-model/expression';

export interface BaseStylingRule {
  id: Id;
  name: string;
}

export type BaseTarget = {
  id: Id;
};

export enum TargetType {
  App = 1,
  EnviroSamples = 2,
}

export type EnviroSamplesTarget = BaseTarget & {
  type: TargetType.EnviroSamples;
};

export type ConditionOnEnviroSamples = {
  scenarioIds: number[];
  chemicalIds: number[];
};

export type StylingRuleOnEnviroSamplesStyle = SampleStyle | {};

export interface FigureStylingRuleOnEnvrioSamples extends BaseStylingRule {
  target: EnviroSamplesTarget;
  condition: ConditionOnEnviroSamples;
  style: StylingRuleOnEnviroSamplesStyle;
  figureId: number;
}

export type AppTarget = BaseTarget & {
  type: TargetType.App;
  collectionType: CollectionType;
  sectionId: number;
};

export type ConditionOnApp = {
  fieldId: number;
  operator: string;
  operand: { value: string | null; value2: string | null };
};

export type StylingRuleOnAppStyle =
  | SampleStyle
  | PolygonStyle
  | PolylineStyle
  | {};

export interface StylingRuleOnApp extends BaseStylingRule {
  target: AppTarget;
  condition: ConditionOnApp;
  style: StylingRuleOnAppStyle;
}

export type FigureId = number | undefined;

export interface FigureStylingRuleOnApp extends StylingRuleOnApp {
  figureId: FigureId;
}

export type FigureStylingRule =
  | FigureStylingRuleOnEnvrioSamples
  | FigureStylingRuleOnApp;

export type AppStylingRule =
  | Omit<FigureStylingRuleOnEnvrioSamples, 'figureId'>
  | Omit<FigureStylingRuleOnApp, 'figureId'>;

export const getFigureStylingRulesOnLayer = (
  map: Map,
  figure: Figure,
  layerModelId: Id
): FigureStylingRule[] => {
  const storeApi = getStoreApi(map);

  const fsrs = storeApi.findFigureStylingRulesByFigureId(figure.id);
  if (!fsrs.length) {
    return [];
  }

  let layerModel = storeApi.findLayerModelById(layerModelId);
  if (!layerModel) {
    return [];
  }

  let result: any[] = [];
  const { type } = layerModel.geojson.properties;
  if (type === LayerType.SAMPLE_GROUP) {
    const sampleGroup = layerModel as SampleGroup;
    const fsrsOnEnviroSamples = fsrs.filter(
      (fsr) =>
        fsr.target.type === TargetType.EnviroSamples &&
        fsr.target.id === sampleGroup.id
    );
    result.push(...fsrsOnEnviroSamples);

    const samples = storeApi.getScopedSamples(sampleGroup);
    const appIds = _uniq(
      samples
        .map((sample) => {
          const { template_tab_id: appId } = sample;
          return appId;
        })
        .filter((appId) => !!appId)
    );
    const fsrsOnApp = fsrs.filter(
      (fsr) =>
        fsr.target.type === TargetType.App &&
        fsr.target.collectionType === DrawingType.Point &&
        appIds.includes(fsr.target.id)
    );
    result.push(...fsrsOnApp);
  } else if (
    [
      LayerType.POLYGON,
      LayerType.POLYLINE,
      LayerType.ARROW,
      LayerType.RECTANGLE,
      LayerType.CIRCLE,
      LayerType.HEDGE,
    ].includes(type)
  ) {
    const polySample = storeApi.findPolySampleByLayerModelId(
      layerModel.id as Id
    );
    if (polySample) {
      const fsrsOnApp = fsrs.filter(
        (fsr) =>
          fsr.target.type === TargetType.App &&
          [DrawingType.Polygon, DrawingType.Polyline].includes(
            fsr.target.collectionType
          ) &&
          fsr.target.id === polySample.template_tab_id
      );
      result.push(...fsrsOnApp);
    }
  }
  return result;
};

export function checkIsFigureStylingRuleOnEnviroSamples(
  fsr: FigureStylingRule
): fsr is FigureStylingRuleOnEnvrioSamples {
  return fsr.target.type === TargetType.EnviroSamples;
}

export function checkIsFigureStylingRuleOnApp(
  fsr: FigureStylingRule
): fsr is FigureStylingRuleOnApp {
  return fsr.target.type === TargetType.App;
}

export function checkIsFigureStylingRuleOnAppFollowed(
  fsr: FigureStylingRuleOnApp,
  field: GatherField,
  inputValuesForStyling: InputValue[]
): boolean {
  const { target, condition } = fsr;
  const { id: appId, sectionId } = target;
  const { fieldId, operator, operand } = condition;
  const inputValue = inputValuesForStyling.find(
    (iv) =>
      iv.template_tab_id === appId &&
      iv.template_section_id === sectionId &&
      iv.template_field_id === fieldId &&
      iv.template_section_index === 0
  );

  if (!inputValue) {
    return false;
  }

  const { value, value2 } = inputValue;
  const values: (string | Result)[] = [];
  const leftOperand = resolveFieldFromValuePairs(
    field,
    ACTION_CURRENT,
    [{ value, value2 }],
    0
  );
  const rightOperand = resolveFieldFromValuePairs(
    field,
    ACTION_CURRENT,
    [operand],
    0
  );
  if (operator === Operator.EqualTo) {
    values.push('isEqual(');
    values.push(leftOperand);
    values.push(', ');
    values.push(rightOperand);
    values.push(')');
  } else if (operator === Operator.NotEqualTo) {
    values.push('!isEqual(');
    values.push(leftOperand);
    values.push(', ');
    values.push(rightOperand);
    values.push(')');
  } else {
    values.push(leftOperand);
    values.push(operator);
    values.push(rightOperand);
  }

  try {
    return evaluateValues(values);
  } catch (e) {
    if (checkIsExpressionReady(values)) {
      console.error(
        `Checking whether the styling rule on app is followed or not failed, styling rule: ${fsr.id}, values: ${values}.`
      );
    }
    return false;
  }
}
