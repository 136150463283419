import { captureMessage } from '@component-library/sentry';
import { createRequestOptions, proxify } from '../../../common/network';
import type {
  MapServerFolderLayerModel,
  MapServerItemLayerModel,
} from './types';
import { externalClient } from '@component-library/api';

export default async function requestMapServerLayerRenderers(
  folderModel: MapServerFolderLayerModel
) {
  const { properties } = folderModel.geojson;
  const { shouldUseCorsProxy, token } = properties;

  let { url } = properties;

  url += '/legend?f=pjson';

  if (token) {
    url += '&token=' + token;
  }

  if (shouldUseCorsProxy) {
    url = proxify(url);
  }

  const {
    data: { layers: legends },
  } = await externalClient.get(url, createRequestOptions());
  if (!legends) {
    captureMessage(`Failed to get legends of service layer: ${url}`);
  }

  return folderModel.children.map((child: MapServerItemLayerModel) => {
    const childLegend = legends?.find(
      (legend) => legend.layerId == child.geojson.properties.index
    );

    return {
      id: child.id,
      title: childLegend
        ? childLegend.layerName || childLegend.name
        : 'Not found',
      renderer: childLegend
        ? {
          type: 'uniqueValue',
          uniqueValueInfos: childLegend.legend,
        }
        : null,
    };
  });
}
