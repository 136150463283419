<script lang="ts" setup>
import { useToastStore } from '@component-library/store/toasts';
import { nextTick, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';
import useAuth from '@component-library/composables/useAuth';
import { useNavigationStore } from '@component-library/store/navigation';
import Spinner from '@component-library/components/Spinner.vue';

const toastStore = useToastStore();
const navigationStore = useNavigationStore();
const auth = useAuth();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  const { access_token, page } = route.params;
  if (route.query.demo === '1') {
    navigationStore.enableDemoMode();
  } else {
    navigationStore.disableDemoMode();
  }
  try {
    const data = await auth.loginWithHandoverToken(access_token, true);
    if (!data.project?.project_id) {
      toastStore.error('No project selected.');
      console.error('No project selected.', data);
      router.replace('/projects');
      return;
    }

    const { project_id } = data.project;

    if (page) {
      router.replace({
        name: page,
        ...(project_id
          ? { params: { project_id: project_id.toString() } }
          : {}),
      });
      return;
    }

    router.replace(`/${project_id}/map`);
  } catch (err) {
    toastStore.error('Your authentication has expired, please try again.');
    console.error('Your authentication has expired, please try again.');
    router.replace('/projects');
    return;
  }
});
</script>

<template>
  <div class="loading-card">
    <div class="card text-center col-12 col-md-4">
      <div class="card-body">
        <Spinner xl />
        <div class="mb-0 mt-4">
          <h2 class="fw-bolder">Please Wait!</h2>
          <h6 class="text-muted mb-0">We're locating your project...</h6>
        </div>
      </div>
    </div>
  </div>
</template>
