import { defineStore } from 'pinia';
import useApi from '../../../api';
import { ref } from 'vue';
import type { User } from '../../../user';

export const useSubscribedUsers = defineStore('subscribed-users', () => {
  const api = useApi();

  const projectId = ref<number | null>(null);
  const hasChangedProjectId = ref(false);

  const isLoadingUsers = ref(false);
  const users = ref<User[]>([]);

  const setProjectId = (id: number) => {
    if (id !== projectId.value) {
      projectId.value = id;
      hasChangedProjectId.value = true;
    }
  };

  const loadUsers = async (reset = false) => {
    if (!hasChangedProjectId.value && !reset) {
      return;
    }

    try {
      isLoadingUsers.value = true;

      const { data } = await api.get('/project/list-subscribed-team', {
        params: {
          project_id: projectId.value,
        },
      });

      users.value = data.users;
      hasChangedProjectId.value = false;
    } catch (e) {
      throw e;
    } finally {
      isLoadingUsers.value = false;
    }
  };

  return {
    setProjectId,
    loadUsers,
    users,
    isLoadingUsers,
  };
});
