import useProjectOfflineManager, {
  ProjectOfflineManagerReturnType,
} from '@/js/composables/useProjectOfflineManager';
import { OfflineStorageManagerReturnTypeSW } from '@component-library/composables/useOfflineStorageManagerSW';
import {
  OfflineProject,
  ProjectOfflineRequest,
} from '@component-library/offline-data';
import useSampleOfflineManager from './useSampleOfflineManager';
import useLayerOfflineManager from './useLayerOfflineManager';
import { post } from '../helpers/fetch-api';
import { DATANEST_URL } from '@component-library/env';
import { blobToBase64 } from '@component-library/utils/download';
import localforage from 'localforage';

export default function useOfflineDownload(
  offlineStorageManager: OfflineStorageManagerReturnTypeSW
) {
  const checkForOfflineDataDownload = async () => {
    console.log('downloading offline data');

    const projectOfflineManager = useProjectOfflineManager(
      offlineStorageManager,
      false
    );

    await offlineStorageManager.loadProjects();

    const offlineRequests =
      await projectOfflineManager.getProjectOfflineRequests();
    if (offlineRequests.length === 0) {
      console.error('Could not find request for offline data.');
      return;
    }

    console.log(`starting downloading ${offlineRequests.length} requests`);

    for (const offlineRequest of offlineRequests) {
      await downloadOfflineRequest(offlineRequest, projectOfflineManager);
    }
  };

  const downloadOfflineRequest = async (
    offlineRequest: ProjectOfflineRequest,
    projectOfflineManager: ProjectOfflineManagerReturnType
  ) => {
    console.log('located request data', offlineRequest);

    let projectOfflineRequestId: number | null = null;

    const {
      updateProjectOfflineRequestStatus,
      cacheProjectForOffline,
      cacheMarkerIcons,
    } = projectOfflineManager;

    try {
      projectOfflineRequestId = offlineRequest.id;

      const { figure_id, basemap, radius, data } = offlineRequest;

      const appIds = data
        .filter((d) => d.app_id !== null)
        .map((s) => s.app_id!);

      const sampleIds = projectOfflineManager.getNewlyRequestedSamples(
        offlineRequest.id,
        offlineRequest.project_id,
        data.filter((d) => d.sample_id !== null).map((s) => s.sample_id!)
      );

      const project = await cacheProjectForOffline(
        offlineRequest,
        appIds,
        figure_id!
      );

      if (!project) {
        throw new Error('Failed to find project.');
      }

      await updateProjectOfflineRequestStatus(offlineRequest.id, 'is_syncing');

      console.log('located project');

      console.log('taking samples offline', sampleIds);
      const { cacheSamplesForOffline } = useSampleOfflineManager(
        offlineStorageManager,
        project
      );

      const samples = await cacheSamplesForOffline(sampleIds);
      console.log('located samples', samples);

      const { cacheMapTiles } = useLayerOfflineManager(
        offlineStorageManager,
        project
      );

      await Promise.all([
        cacheMarkerIcons(project),
        cacheMapTiles(basemap, radius),
      ]);

      await updateProjectOfflineRequestStatus(offlineRequest.id, 'synced');

      console.log('successfully synced project id:' + project.project_id);
    } catch (e) {
      if (projectOfflineRequestId !== null) {
        await updateProjectOfflineRequestStatus(
          projectOfflineRequestId,
          'failed_sync'
        );
      }

      throw e;
    }
  };

  const adminStoreOfflineData = async (userId: number) => {
    await offlineStorageManager.loadProjects();

    const offlineProjects = offlineStorageManager.offlineProjects.value;

    async function convertBlobsInProjects(projects: OfflineProject[]) {
      async function convert(obj: any) {
        if (Array.isArray(obj)) {
          for (let i = 0; i < obj.length; i++) {
            obj[i] = await convert(obj[i]);
          }
        } else if (obj instanceof Blob) {
          return await blobToBase64(obj);
        } else if (obj && typeof obj === 'object') {
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              obj[key] = await convert(obj[key]);
            }
          }
        }
        return obj;
      }

      return await convert(projects);
    }

    const convertedProjects = await convertBlobsInProjects(offlineProjects);

    const offlineBlob = new Blob([JSON.stringify(convertedProjects)], {
      type: 'application/json',
    });

    const formData = new FormData();

    const device = await localforage.getItem('device');

    formData.append('file', offlineBlob, 'offlineProjects.json');
    formData.append('user_id', userId.toString());
    if (device) {
      formData.append('device', device.toString());
    }

    await post(`${DATANEST_URL}/api/offline/admin/store-data`, formData);
  };

  return {
    checkForOfflineDataDownload,
    adminStoreOfflineData,
  };
}
