import { User } from '@sentry/browser';
import { ChatProvider } from '../chat-service';
import { hasAccess, FEATURES } from '../feature-manager';

declare global {
  interface Window {
    fcWidget: any;
  }
}

function loadScript() {
  if (hasLiveChat()) {
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.defer = true;
  scriptTag.async = true;
  scriptTag.setAttribute('chat', 'true');
  scriptTag.onload = () => {
    const interval = setInterval(() => {
      if (!window.fcWidget) {
        return;
      }
      clearInterval(interval);
      window.fcWidget.on('widget:closed', () => {
        const fcFrame = document.getElementById('fc_frame');
        if (!fcFrame) {
          throw new Error('Chat iframe not found');
        }
        fcFrame.classList.remove('d-block');
      });
    }, 250);
  };
  scriptTag.src = '//au.fw-cdn.com/20180925/97721.js';
  document.body.appendChild(scriptTag);
}

function setContext(
  user: User,
  projectId: string | undefined,
  projectName: string | undefined,
  projectNumber: string | undefined
) {
  console.log('Setting chat external id', user.enad_ref, user.name, user.email);

  let hasLiveChat = false;
  let tags = ['standard'];
  if (hasAccess(FEATURES.LIVE_CHAT)) {
    tags = ['priority'];
  }

  // To set user properties
  // Note that any other fields passed as properties which are not in the CRM Contact properties already, they will be ignored.
  window.fcWidget.user.setProperties({
    externalId: user.enad_ref,
    firstName: user.name,
    email: user.email,
    cf_priority_support: hasLiveChat,
    cf_company: user.company?.company_name,
    cf_datanest_company_id: user.company?.id,
    cf_datanest_user_id: user.user_id,
    cf_datanest_project_id: projectId,
    cf_datanest_url: window.location.pathname + window.location.search,
    cf_project_url: projectId
      ? window.location.origin + '/p/' + projectId
      : null,
    cf_project_name: projectName,
    cf_project_number: projectNumber,
  });
  window.fcWidget.setExternalId(user.enad_ref);
  window.fcWidget.user.setFirstName(user.name);
  window.fcWidget.user.setEmail(user.email);
  window.fcWidget.setTags(tags);
}

function open(
  user: User,
  projectId: string | undefined,
  projectName: string | undefined,
  projectNumber: string | undefined
) {
  setContext(user, projectId, projectName, projectNumber);

  // Show the hidden chat iframe
  const fcFrame = document.getElementById('fc_frame');
  if (fcFrame) {
    fcFrame.classList.add('d-block');
  } else {
    console.error('Chat iframe not found');
  }

  window.fcWidget.open();
}

function hasLiveChat() {
  return window.fcWidget !== undefined;
}

export default {
  loadScript,
  setContext,
  open,
  hasLiveChat,
} as ChatProvider;
