<script setup lang="ts">
import { ref } from 'vue';
import { useNavigationStore } from '../store/navigation';

defineProps<{
  isAuthenticated: boolean;
  isStagingEnvironment: boolean;
  moduleName: string;
  logo: string;
}>();
const navigationStore = useNavigationStore();

const closeDrawer = (e: any) => {
  if (e.target.matches('.nav-link') || e.target.matches('.dropdown-item')) {
    navigationStore.showMobileNavigation = false;
  }
};
</script>

<template>
  <div>
    <div
      class="screen-overlay"
      :class="{ show: navigationStore.showMobileNavigation }"
      @click="
        navigationStore.showMobileNavigation =
          !navigationStore.showMobileNavigation
      "
    />
    <nav
      id="navbar_main"
      class="navbar fixed-top navbar-expand-lg d-flex justify-content-between w-100"
      :class="{
        'd-flex align-items-center justify-content-center': !isAuthenticated,
        'border-top border-bottom border-3 border-danger': isStagingEnvironment,
      }"
    >
      <div class="d-flex align-items-center logo-container">
        <div class="d-flex align-items-center">
          <button
            v-if="isAuthenticated"
            class="btn btn-outline-light btn-sm mobile-toggle ms-3"
            type="button"
            @click="
              navigationStore.showMobileNavigation =
                !navigationStore.showMobileNavigation
            "
          >
            <i
              class="fal fa-fw"
              :class="{
                'fa-bars': !navigationStore.showMobileNavigation,
                'fa-times': navigationStore.showMobileNavigation,
              }"
            />
          </button>

          <RouterLink
            class="navbar-brand ms-1"
            :to="isAuthenticated ? '/projects' : '/'"
            style="flex-shrink: 0"
          >
            <img height="40px" :src="logo" />
            <sup
              class="opacity-75 d-none d-md-inline-block"
              style="margin-left: -8px; font-size: 1em"
            >
              {{ moduleName }}
            </sup>
          </RouterLink>

          <slot v-if="isAuthenticated" name="appSwitcher"></slot>
        </div>

        <slot v-if="isAuthenticated" name="rightOptions"></slot>
      </div>

      <div
        class="mobile-offcanvas"
        :class="{
          show: navigationStore.showMobileNavigation,
        }"
      >
        <div
          v-if="isAuthenticated"
          class="container-fluid justify-content-end p-0"
        >
          <ul class="navbar-nav mobile-drawer" @click="closeDrawer">
            <slot name="mobileMenu"></slot>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<style scoped lang="scss">
.navbar {
  padding: 0px;
  height: 56px;
  z-index: 10000 !important;
}

.navbar-brand {
  color: #ffffff !important;
  font-size: 1em;
}

.mobile-toggle {
  display: none;
}

.screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: #212121e6;
  transition: opacity 300ms ease 0s;
}

.screen-overlay.show {
  opacity: 1;
  width: 100%;
  visibility: visible;
}

.logo-container {
  justify-content: space-between;
  width: 100%;
}

.mobile-offcanvas {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

@media all and (max-width: 992px) {
  .mobile-toggle {
    display: block;
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    overflow: hidden;
    top: 56px;
    max-width: 350px;
  }

  html:lang(ar) .mobile-offcanvas {
    transform: translateX(100%);
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .container-fluid {
    padding: 0px;
  }

  .navbar-nav {
    width: 100%;
    overflow-y: auto;
    padding: 0.75rem;
  }

  .mobile-offcanvas {
    background: #ffffff;
  }
}

.gather {
  .navbar {
    background: #4e0695;
  }

  #navbar_main {
    background: #4e0695 !important;
  }
}

.hub {
  .navbar {
    background: #3366a2;
  }

  #navbar_main {
    background: #3366a2 !important;
  }
}
</style>
