import {
  OfflineFigureData,
  OfflineProject,
  ProjectOfflineRequest,
  ProjectOfflineRequestStatus,
} from '@component-library/offline-data';
import _uniq from 'lodash/uniq';
import { getJSON, get } from '../helpers/fetch-api';
import { Project, ProjectType } from '@component-library/project';
import { OfflineStorageManagerReturnTypeSW } from '@component-library/composables/useOfflineStorageManagerSW';
import useFetchMapsApi from './useFetchMapsApi';
import { Exceedance, Figure } from '@maps/lib/olbm/types';
import axios from 'axios';

export type ProjectOfflineManagerReturnType = {
  getProjectOfflineRequests: () => Promise<ProjectOfflineRequest[]>;
  updateProjectOfflineRequestStatus: (
    id: number,
    status: ProjectOfflineRequestStatus
  ) => Promise<void>;
  cacheProjectForOffline: (
    offlineRequest: ProjectOfflineRequest,
    appIds: number[],
    figureId: number
  ) => Promise<OfflineProject | null>;
  cacheMarkerIcons: (project: OfflineProject) => Promise<void>;
  getNewlyRequestedSamples: (
    requestId: number,
    projectId: number,
    sampleIds: number[]
  ) => number[];
};

export default function useProjectOfflineManager(
  offlineStorageManager: OfflineStorageManagerReturnTypeSW,
  useAxios = false
): ProjectOfflineManagerReturnType {
  const getProjectOfflineRequests = async (): Promise<
    ProjectOfflineRequest[]
  > => {
    const { data } = await getJSON('/api/offline/sync/request-data');
    return data.offline_requests;
  };

  const updateProjectOfflineRequestStatus = async (
    id: number,
    status: ProjectOfflineRequestStatus
  ) => {
    await getJSON(`/api/offline/sync/request-data/${id}/status`, {
      params: {
        status,
      },
    });
  };

  const cacheProjectForOffline = async (
    offlineRequest: ProjectOfflineRequest,
    appIds: number[],
    figureId: number
  ): Promise<OfflineProject | null> => {
    const { project_id: projectId } = offlineRequest;

    const {
      data: { project, template_tabs, company_users },
    } = await getJSON('/api/offline/sync/project', {
      params: {
        project_id: projectId,
        app_ids: JSON.stringify(appIds),
      },
    });

    const figureData = await loadFigureData(project, figureId);

    const offlineProject = offlineStorageManager.offlineProjects.value.find(
      (p) => p.project_id == projectId
    );

    if (offlineProject) {
      console.log('updating offline project');

      // Ensure that the data stays consistent, with unique template tabs and imported layers
      await offlineStorageManager.updateOfflineProject(projectId, {
        ...offlineProject,
        ...project,
        ...figureData,
        offline_requests: [...offlineProject.offline_requests, offlineRequest],
        template_tabs: [
          ...offlineProject.template_tabs.filter(
            (t) => template_tabs.findIndex((tt) => tt.id === t.id) === -1
          ), // is this correct, we will update any templates to the latest DB version?
          ...template_tabs,
        ],
        company_users,
      });

      return offlineProject;
    }

    const newProject = {
      ...project,
      ...figureData,
      template_tabs,
      company_users,
      offline_requests: [offlineRequest],
      samples: [],
      loaded_areas: [],
    };

    console.log('creating offline project');

    await offlineStorageManager.createOfflineProject(newProject);

    return newProject;
  };

  const loadFigureData = async (
    project: Project,
    figureId: number
  ): Promise<OfflineFigureData> => {
    const {
      loadFiguresRaw,
      loadLayerModelsRaw,
      loadSubFolders,
      loadScenariosRaw,
      loadChemicals,
      loadExceedances,
      loadChemicalResultsRaw,
    } = useFetchMapsApi(project.project_id, useAxios);

    const {
      figures,
      figure_styling_rules: figureStylingRules,
      template_tab_styling_rules: appStylingRules,
    } = await loadFiguresRaw();

    const { layers: layerModels, poly_gather_samples: polySamples } =
      await loadLayerModelsRaw(figureId);

    const subFolders = await loadSubFolders();

    let result: OfflineFigureData = {
      figures,
      figureStylingRules,
      appStylingRules,
      selectedFigureId: figureId,
      layerModels: layerModels.map((l) => {
        return {
          ...l,
          geojson: JSON.parse(l.geojson),
        };
      }),
      polySamples,
      subFolders,
    };

    const figure = figures.find((f: Figure) => f.id === figureId);

    if (project.project_type === ProjectType.ENVIRO) {
      try {
        const {
          scenarios,
          styles: scenarioStyles,
          scenario_set: scenarioSet,
        } = await loadScenariosRaw();
        const { chemicals } = await loadChemicals();

        let exceedances: Exceedance[] = [];
        try {
          const { exceedances: _exceedances } = await loadExceedances();
          exceedances = _exceedances;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.status === 400) {
            console.warn(
              `The project could have not been assessed: ${project.project_id}`
            );
          } else {
            throw e;
          }
        }

        let chemicalResults = [];
        if (figure.chemicals?.length) {
          const { chemicals: _chemicalResults } = await loadChemicalResultsRaw(
            figure.chemicals
          );
          chemicalResults = _chemicalResults;
        }

        result = {
          ...result,
          scenarios,
          scenarioStyles,
          scenarioSet,
          chemicals,
          exceedances,
          chemicalResults,
        };
      } catch (e) {
        console.error(e);
      }
    }

    return result;
  };

  const cacheMarkerIcons = async (project: OfflineProject) => {
    const appMarkers = project.template_tabs.map(
      (t) => `${t.point_icon}_${t.drawing_colour}`
    );

    const sampleGroupMarkers = project.layerModels
      .filter((l) => l.marker_identifier)
      .map((l) => l.marker_identifier);

    const markers = _uniq([...appMarkers, ...sampleGroupMarkers, '0_#000000']);

    const markerUrls = _uniq(
      markers.map((m) => {
        const items = m.split('_');
        const icon = parseInt(items[0], 10) || 0;
        const color = encodeURIComponent(items[1] || '#000000');
        return `/markers/${icon + 1}?fill=${color}&stroke=${color}`;
      })
    );

    const cache = await caches.open('map-markers');

    for (let i = 0; i < markerUrls.length; i++) {
      const url = markerUrls[i];

      console.log(`caching marker icon ${url}`);

      let response = await cache.match(url);
      if (!response) {
        response = await get(
          url + '&width=18&height=18',
          {},
          {
            mode: 'no-cors',
          }
        );

        await cache.put(url, response.clone());
      }
    }
  };

  /**
   * When an update to a offline request comes in, we don't want to request the same data again.
   */
  const getNewlyRequestedSamples = (
    projectId: number,
    requestId: number,
    sampleIds: number[]
  ) => {
    const existingOfflineProject =
      offlineStorageManager.offlineProjects.value.find(
        (p) => p.project_id == projectId
      );

    const offlineRequest = existingOfflineProject
      ? existingOfflineProject.offline_requests.find((r) => r.id === requestId)
      : null;

    if (!existingOfflineProject || !offlineRequest) {
      return sampleIds;
    }

    sampleIds = sampleIds.filter(
      (id) =>
        existingOfflineProject.samples.findIndex((t) => t.id === id) === -1
    );

    return sampleIds;
  };

  return {
    getProjectOfflineRequests,
    updateProjectOfflineRequestStatus,
    cacheProjectForOffline,
    cacheMarkerIcons,
    getNewlyRequestedSamples,
  };
}
