<template>
  <Loader v-if="isLoading" />
  <div v-else-if="!isLoading && sample">
    <div class="text-center">
      <div
        style="height: 61px"
        class="section-header p-3 d-flex align-items-center justify-content-center flex-column bg-light fw-bold text-center mb-4"
      >
        <h6 class="mb-0">
          Version control for <b> {{ sample.custom_title }} </b>
        </h6>
        <small class="text-muted fw-normal d-block mt-1">
          Manage all iterations of your samples values, simply select between
          these different states.
        </small>
      </div>
    </div>

    <div
      v-if="sample.deleted_at"
      class="alert alert-danger col-12 col-md-6 mb-4"
    >
      <i class="fas fa-exclamation me-2" /> It appears this item was deleted,
      you are able to revert any of the changes but please be aware this will
      add the sample back to your project.
    </div>

    <ControlTable
      :sample="sample"
      :versionCreatedAts="versionCreatedAts"
      :inputValues="inputValues"
      :users="users"
      :template="template"
      @revertValuesToVersion="revertValuesToVersion"
    />

    <div class="d-flex align-items-center justify-content-center mt-3">
      <small class="legend-item me-2">
        <div class="icon bg-warning" />
        Represents Changed Value
      </small>
      <small class="legend-item">
        <div class="icon bg-danger" />
        Represents Deleted Value
      </small>
    </div>
  </div>
</template>
<script>
import api from './api/index.js';
import Loader from './components/Loader.vue';
import ControlTable from './components/ControlTable.vue';

export default {
  props: {
    sampleId: Number,
  },
  data: () => ({
    isLoading: false,

    sample: null,
    template: null,
    versionCreatedAts: [],
    inputValues: [],
    users: [],
  }),
  components: { Loader, ControlTable },
  methods: {
    async revertValuesToVersion({ versionCreatedAt, value }) {
      try {
        await api.revertValuesToVersion({
          sample_id: this.sample.id,
          versionCreatedAt,
          value_id: value?.id,
        });

        this.$store.dispatch('updatePersistence', {
          sampleData: null,
          sampleIdentifier: null,
          inputValues: [],
          sampleTabId: null,
        });

        this.getSampleVersioning();
      } catch (e) {
        this.$toastStore.error(
          'Failed to restore value to old version, try again.'
        );
        throw e;
      }
    },
    async getSampleVersioning() {
      this.isLoading = true;

      try {
        let { data } = await api.getSampleVersioning(this.sampleId);

        this.sample = data.sample;
        this.versionCreatedAts = data.version_created_ats;
        this.inputValues = Object.freeze(
          data.input_values.map((item) => {
            try {
              item.value = JSON.parse(item.value);
            } catch (e) {}
            return item;
          })
        );
        this.users = Object.freeze(data.users);

        ({ data } = await api.getTemplateTab(this.sample.template_tab_id));

        this.template = data.template_tab;
        this.isLoading = false;
      } catch (e) {
        this.$toastStore.error('Failed to load different versions, try again.');
        throw e;
      }
    },
  },
  mounted() {
    this.getSampleVersioning();
  },
};
</script>
<style scoped>
.legend-item {
  display: flex;
  align-items: center;
}

.legend-item .icon {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-right: 6px;
}
</style>
