<script lang="ts" setup>
/**
 * !! Notice to developers, consider using Dialog Store instead of NotifyModal !!
 */
import Modal from './Modal.vue';
import ButtonSpinner from './ButtonSpinner.vue';
import { computed, onMounted, ref, useSlots } from 'vue';

const slots = useSlots();

const props = defineProps<{
  headerMessage?: string;
  bodyMessage?: string;
  isDelete?: boolean;
  isDanger?: boolean;
  hideSubmit?: boolean;
  yesOrNoOption?: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  notClosable?: boolean;
  requireConfirmation?: boolean;
  fullWidthButtons?: boolean;
}>();

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'submit'): void;
}>();

const confirmText = ref('');
const isDisabled = computed(() => {
  if (!props.requireConfirmation) {
    return false;
  }

  return confirmText.value.toLowerCase().trim() != 'i accept';
});
const hasSlot = computed(() => {
  return !!slots.default;
});

const getCancelButtonClass = computed(() => {
  if (props.fullWidthButtons) {
    return 'btn btn-flat';
  }
  return 'btn btn-outline-secondary';
});

function close() {
  emit('close');
}
function submit() {
  emit('submit');
}

onMounted(() => {
  console.warn(
    'Deprecated: NotifyModal is deprecated. Use Dialog Store instead'
  );
});
</script>

<template>
  <Modal
    @close="close"
    @submit="submit"
    :isLoading="isLoading"
    :notClosable="notClosable"
  >
    <template #header>
      {{ headerMessage }}
    </template>
    <template v-if="bodyMessage != null || requireConfirmation || hasSlot">
      <div
        :class="{
          'text-center': fullWidthButtons,
        }"
      >
        <slot />

        <div v-if="bodyMessage != null" v-html="bodyMessage" />

        <div
          v-if="requireConfirmation"
          class="mb-0"
          :class="{
            'mt-3': bodyMessage,
          }"
        >
          <label class="form-label" for="confirmText">
            Type <b>I Accept</b> To Confirm
          </label>
          <input
            class="form-control"
            placeholder="I accept"
            autofocus
            v-model="confirmText"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="me-2"
        :class="[getCancelButtonClass]"
        @click="close"
      >
        {{ yesOrNoOption ? 'No' : 'Cancel' }}
      </button>

      <ButtonSpinner
        v-if="!hideSubmit"
        type="submit"
        class="btn"
        :class="[isDelete || isDanger ? 'btn-danger' : 'btn-primary']"
        :is-loading="isSubmitting"
        :disabled="isDisabled"
        @click.native.prevent="submit"
        data-cy="notify-modal-submit"
      >
        {{ isDelete ? 'Delete' : yesOrNoOption ? 'Yes' : 'Ok' }}
      </ButtonSpinner>
    </template>
  </Modal>
</template>
