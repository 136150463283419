<script lang="ts" setup>
import InputSelect from '@component-library/components/InputSelect.vue';
import { GatherFieldOptions } from '@component-library/gather';
import { computed } from 'vue';

const props = defineProps<{
  options: GatherFieldOptions;
}>();

const emit = defineEmits<{
  (event: 'updateOptions', value: GatherFieldOptions): void;
  (event: 'blur'): void;
}>();

const hasAutoAssignCreator = computed({
  get() {
    return props.options.has_auto_assign_creator;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      has_auto_assign_creator: value,
    });
  },
});

const hasAutoAssignEditor = computed({
  get() {
    return props.options.has_auto_assign_editor;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      has_auto_assign_editor: value,
    });
  },
});
const isReadonly = computed({
  get() {
    return props.options.is_readonly;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      is_readonly: value,
    });
  },
});

const userFilter = computed({
  get() {
    return props.options.user_filter;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      user_filter: value,
    });
  },
});

const userProperty = computed({
  get() {
    return props.options.user_property;
  },
  set(value) {
    emit('updateOptions', {
      ...props.options,
      user_property: value,
    });
  },
});
</script>

<template>
  <div>
    <div class="mb-2">
      <label class="form-label w-100">Options</label>
      <InputSelect v-model="userFilter">
        <option :value="undefined">Project Team Members</option>
        <option value="company">Company Users</option>
        <option value="company_team">
          Company Users & Project Team Members
        </option>
      </InputSelect>
    </div>
    <div class="mb-2">
      <label class="form-label w-100">Property to show</label>
      <InputSelect v-model="userProperty">
        <option :value="undefined">User's Name</option>
        <option value="email">User's Email</option>
        <option value="uuid">User's UUID</option>
      </InputSelect>
    </div>

    <div class="form-check" v-if="hasAutoAssignEditor || hasAutoAssignCreator">
      <label class="form-check-label">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="isReadonly"
          @keypress.enter="emit('blur')"
        />
        Read only
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="hasAutoAssignCreator"
          @keypress.enter="emit('blur')"
        />
        Auto Assign from item creator
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="hasAutoAssignEditor"
          @keypress.enter="emit('blur')"
        />
        Auto Assign from last editor of item
      </label>
    </div>
  </div>
</template>
