<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { OfflineProject } from '@component-library/offline-data';
import AppItemPicker from '@component-library/components/offline-management/AppItemPicker.vue';
import SamplePicker from '@component-library/components/offline-management/SamplePicker.vue';
import useTogglableSampleList from '@component-library/components/offline-management/composables/useTogglableSampleList';
import axios from 'axios';
import AlertBox from '@component-library/components/AlertBox.vue';
import useAuth from '@component-library/composables/useAuth';
const auth = useAuth();

const props = defineProps<{
  project: OfflineProject;
  selectedSampleIds: (number | string)[];
}>();

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'setSelectedSampleIds', ids: (number | string)[]): void;
}>();

const toggledAppIds = ref<number[]>(
  props.project.template_tabs.map((t) => t.id)
);
const invalidatedSampleIds = ref<(number | string)[]>([]);

const samples = computed(() => {
  return props.project.samples.filter(
    (s) => s.offline_user_id === auth.user().user_id || !s.offline_user_id
  );
});

const selectedAppIds = computed(() => {
  return toggledAppIds.value;
});

const selectedSampleIds = computed(() => {
  return props.selectedSampleIds;
});

const toggleSelectAll = () => {
  if (selectedSampleIds.value.length > 0) {
    emit('setSelectedSampleIds', []);
    toggledAppIds.value = [];
  } else {
    emit(
      'setSelectedSampleIds',
      samples.value.map((s) => s.id!)
    );
    toggledAppIds.value = props.project.template_tabs.map((t) => t.id);
  }
};

const hasInvalidatedSamples = computed(() => {
  return samples.value.some((s) => invalidatedSampleIds.value.includes(s.id));
});

const nonGroupedSamples = computed(() => {
  return samples.value.filter((s) => !s.template_tab_id);
});

const {
  toggleAppDropdown,
  toggleApp,
  toggleSample,
  toggleSamples,
  isAppDropdownOpen,
} = useTogglableSampleList(
  samples,
  selectedSampleIds,
  selectedAppIds,
  () => {},
  (selectedAppIds: number[]) => (toggledAppIds.value = selectedAppIds),
  (selectedSampleIds: number[]) =>
    emit('setSelectedSampleIds', selectedSampleIds)
);

onMounted(async () => {
  const sampleIds = samples.value.map((s) => s.id);
  if (sampleIds.length === 0) {
    return;
  }

  const { data } = await axios.get('/api/offline/upload/invalidated-samples', {
    params: {
      sampleIds: sampleIds,
      project_id: props.project.project_id,
    },
  });

  invalidatedSampleIds.value = data.invalidated_sample_ids;
});
</script>
<template>
  <div>
    <div class="d-flex align-items-center w-100">
      <div class="pe-3 flex-fill overflow-hidden">
        <span class="d-block fw-medium overflow-hidden mb-0">
          {{
            project.project_number +
            (project.project_name ? ' - ' + project.project_name : '')
          }}
        </span>
        <small>{{ project.project_client }}</small>
      </div>
    </div>

    <hr />

    <div class="d-flex align-items-center justify-content-between mb-3">
      <h6 class="mb-0 d-block">Bring back online</h6>
    </div>

    <span class="fw-medium d-block mb-3 mt-2">
      Items to bring online
      <a href="#" @click.prevent="toggleSelectAll" class="ms-1">
        {{ selectedSampleIds.length > 0 ? 'Deselect all' : 'Select all' }}
      </a>
    </span>

    <AlertBox v-if="hasInvalidatedSamples" type="warning">
      You have items that no longer belong to your user. These have been
      invalidated. By selecting them it will take your version online.
    </AlertBox>

    <AppItemPicker
      v-for="app in project.template_tabs"
      :app="app"
      :samples="samples"
      :selectedSampleIds="selectedSampleIds"
      :isAppToggled="selectedAppIds.includes(app.id)"
      :isAppDropdownToggled="isAppDropdownOpen(app.id)"
      :isUpload="true"
      @toggleApp="toggleApp"
      @toggleAppDropdown="toggleAppDropdown"
      @toggleSample="toggleSample"
      @toggleSamples="toggleSamples"
    />

    <template v-if="nonGroupedSamples.length > 0">
      <span class="fw-medium d-block my-2">Items not in apps</span>
      <SamplePicker
        :selectedSampleIds="selectedSampleIds"
        :samples="nonGroupedSamples"
        :isUpload="true"
        @toggleSample="toggleSample"
        class="list-group-root"
      />
    </template>
  </div>
</template>
