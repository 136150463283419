<script setup lang="ts">
import Slider from '../../Slider.vue';
import StrokePatternChooser from '../../StrokePatternChooser.vue';
import { injectContext } from './context';

withDefaults(
  defineProps<{
    patternName?: string;
    patternLabel?: string;
    thicknessName?: string;
    thicknessLabel?: string;
    opacityName?: string;
    opacityLabel?: string;
  }>(),
  {
    patternName: 'outlineStyle',
    patternLabel: 'Outline pattern',
    thicknessName: 'weight',
    thicknessLabel: 'Outline thickness',
    opacityName: 'opacity',
    opacityLabel: 'Outline opacity',
  }
);

const { getShapeProperty, setShapeProperty } = injectContext();
</script>

<template>
  <div>
    <div class="mb-2">
      <StrokePatternChooser
        :label="patternLabel"
        :selectedStrokePattern="getShapeProperty(patternName, 0) + 1"
        @change="(value) => setShapeProperty(patternName, value - 1)"
      />
    </div>

    <Slider
      class="mb-1"
      name="thickness"
      :label="thicknessLabel"
      :value="getShapeProperty(thicknessName, 3)"
      units="pt"
      :min="2"
      :max="20"
      :step="1"
      @input="(value) => setShapeProperty(thicknessName, value)"
    />

    <Slider
      name="opacity"
      :label="opacityLabel"
      units="%"
      :value="getShapeProperty(opacityName, 1)"
      :formatValue="(value) => Math.round(value * 100).toString()"
      :min="0"
      :max="1"
      :step="0.1"
      @input="(value) => setShapeProperty(opacityName, value)"
    />
  </div>
</template>
