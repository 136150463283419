<template>
  <Teleport to="body">
    <div class="modal fade" id="canvasModalToggle" tabindex="-1">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body p-0" id="canvasBody">
            <PaintingField v-if="showCanvas" ref="painting-field" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';
import Teleport from 'vue2-teleport';

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    inputValue: {
      required: true,
    },
  },
  data: () => ({
    showCanvas: false,
    modal: null,
  }),
  components: {
    Teleport,
    PaintingField: () => import('./PaintingField.vue'),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
  },
  watch: {
    inputValue(updated) {
      if (!updated) return;
    },
    value(updated) {
      if (updated) {
        this.modal.show();
      }
      if (!updated) {
        this.modal.hide();
        const data = this.$refs['painting-field'].getData();
        this.dataUrlToBlob(data.dataURL)
          .then((file) => {
            this.$emit(
              'setDrawing',
              { src: URL.createObjectURL(file), blob: file, type: 'png' },
              JSON.stringify(data.json)
            );
          })
          .catch((e) => {
            this.$emit('setDrawing', { file: null, json: data.json });
          })
          .finally(() => {
            this.$nextTick(() => {
              this.$emit('setCloseDrawing', true);
            });
          });
      }
    },
  },
  methods: {
    dataUrlToBlob(data) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(data);
          const blob = await response.blob();
          const file = new File([blob], 'canvas-drawing.png', {
            type: 'image/png',
            lastModified: new Date(),
          });
          resolve(file);
        } catch (e) {
          console.error('Failed to create image', e);
          reject(e);
        }
      });
    },
  },
  mounted() {
    const modalEl = document.getElementById('canvasModalToggle');
    this.modal = new Modal(modalEl);
    modalEl.addEventListener('shown.bs.modal', () => {
      this.show = true;
      this.showCanvas = true;
      this.$nextTick(() => {
        if (!!this.inputValue?.value2) {
          const data =
            typeof this.inputValue.value2 == 'string'
              ? this.inputValue.value2
              : JSON.stringify(this.inputValue.value2);
          this.$refs['painting-field'].setData(data);
        }
      });
    });
    modalEl.addEventListener('hidden.bs.modal', () => {
      this.showCanvas = false;
      this.show = false;
    });
  },
};
</script>

<style scoped>
.offcanvas {
  z-index: 1090 !important;
}

#canvasModalToggle .modal-fullscreen {
  padding: 0px !important;
}

#canvasModalToggle .modal-content {
  max-height: 100% !important;
}

#canvasModalToggle .modal-body {
  max-height: calc(100vh - 50px);
  top: 50px;
}
</style>
