<script setup lang="ts">
import { computed, onMounted, nextTick } from 'vue';
import InputSelectFloating from '../InputSelectFloating.vue';
import ButtonSpinner from '../ButtonSpinner.vue';
import { User } from '../../user';
import { Figure } from '../../maps';
import { getPushNotificationDevice, getUserTitle } from './helpers/user';
import { useSubscribedUsers } from './composables/useSubscribedUserStore';
import useAuth from '../../composables/useAuth';
const auth = useAuth();

const props = defineProps<{
  projectId: number;
  userId: number;
  userDevice?: string | null;
  figures: Figure[];
  figureId: number | null;
}>();

const subscribedUsersStore = useSubscribedUsers();
const { setProjectId, loadUsers } = subscribedUsersStore;

const emit = defineEmits<{
  (e: 'selectUserId', userId: number): void;
  (e: 'selectUserDevice', device: string | null): void;
  (e: 'selectFigureId', figureId: number): void;
  (e: 'toggleEnabledPushNotifications', value: boolean): void;
}>();

const selectedUser = computed(() => {
  return subscribedUsersStore.users.find((u) => u.user_id === props.userId);
});

function isPushNotificationsEnabled(user?: User) {
  return !!(
    user &&
    user.push_subscriptions &&
    user.push_subscriptions.length > 0
  );
}

function selectUserId(id: number) {
  emit('selectUserId', id);

  const user = subscribedUsersStore.users.find((u) => u.user_id === id);
  emit('selectUserDevice', getPushNotificationDevice(user));

  nextTick(() => {
    emit(
      'toggleEnabledPushNotifications',
      isPushNotificationsEnabled(selectedUser.value)
    );
  });
}

onMounted(async () => {
  setProjectId(props.projectId);

  if (auth.user().company.is_force_local_gather_offline_mode) {
    selectUserId(auth.user().user_id);
    return;
  }

  await loadUsers();

  if (subscribedUsersStore.users.length > 0) {
    const currentUser = subscribedUsersStore.users.find(
      (u) => u.user_id === auth.user().user_id
    );

    if (currentUser) {
      selectUserId(currentUser.user_id);
    } else {
      selectUserId(subscribedUsersStore.users[0].user_id);
    }
  }
});
</script>

<template>
  <div>
    <div
      v-if="!auth.user().company.is_force_local_gather_offline_mode"
      class="d-flex align-items-start mb-2"
    >
      <InputSelectFloating
        required
        class="w-100"
        name="user"
        label="User to download on behalf of"
        :value="userId"
        :errorBag="
          selectedUser && !isPushNotificationsEnabled(selectedUser)
            ? {
                user: 'This user has either not installed the app, or has not enabled notifications yet.',
              }
            : {}
        "
        :isDisabled="subscribedUsersStore.isLoadingUsers"
        @input="selectUserId"
      >
        <option
          v-for="user in subscribedUsersStore.users"
          :key="`user-${user.user_id}`"
          :value="user.user_id"
        >
          {{ getUserTitle(user) }}
        </option>
      </InputSelectFloating>

      <ButtonSpinner
        type="button"
        class="btn btn-sm btn-light ms-2 text-nowrap"
        :is-loading="subscribedUsersStore.isLoadingUsers"
        @click.prevent.native="loadUsers(true)"
      >
        <i class="fal fa-sync" />
      </ButtonSpinner>
    </div>

    <template v-if="figures.length > 0">
      <InputSelectFloating
        required
        class="mb-1"
        name="mode"
        label="Figure"
        :value="figureId"
        @input="emit('selectFigureId', $event)"
      >
        <option
          v-for="figure in figures"
          :key="`figure-${figure.id}`"
          :value="figure.id"
        >
          {{ figure.title }}
        </option>
      </InputSelectFloating>

      <small class="d-block text-muted mb-2">
        All layers except service layers will be downloaded for the selected
        figure.
      </small>
    </template>
  </div>
</template>
