<script setup lang="ts">
import AlertBox from '@component-library/components/AlertBox.vue';
import useItemsFromAppTitle, {
  mapFormContextType,
  useItemsCountFromAppTitle,
} from '@component-library/composables/useItemsFromAppTitle';
import type { App, Item } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import { captureException } from '@component-library/sentry';
import { useCollectionStore } from '@component-library/store/collection';
import { computed, inject, ref, watchEffect } from 'vue';
import api from '../../api';

const props = defineProps<{ item: Item; app: App; linkedApp: App }>();
const emit = defineEmits<{
  (event: 'copied', copies: Item[]): void;
}>();

const collectionStore = useCollectionStore();
const formContext = inject<any>('formContext');
const sampleModal = inject<any>('sampleModal', null);
const sourceItemSelectId = `sourceItemSelect-${makeId()}`;
const selectedSourceItemId = ref<number | string | null>(null);

function useSourceItems() {
  const { isLoading, items } = useItemsFromAppTitle(
    mapFormContextType(formContext.type),
    () => props.app.title
  );
  return {
    isLoadingSourceItems: isLoading,
    sourceItems: computed<Item[]>(() =>
      items.value.filter((item) => item.id !== props.item.id)
    ),
  };
}
const { isLoadingSourceItems, sourceItems } = useSourceItems();
function useLinkedItemsCount() {
  const { isLoading, count } = useItemsCountFromAppTitle(
    mapFormContextType(formContext.type),
    () => props.linkedApp.title,
    () => ({ linkedItemId: selectedSourceItemId.value })
  );

  return { isLoadingLinkedItemsCount: isLoading, linkedItemsCount: count };
}
const { isLoadingLinkedItemsCount, linkedItemsCount } = useLinkedItemsCount();

function gotoSourceItem() {
  if (!selectedSourceItemId.value) {
    throw new Error('gotoSourceItem: No option selected');
  }
  if (!sampleModal) {
    throw new Error('gotoSourceItem: No sample modal');
  }
  sampleModal.gotoItem(selectedSourceItemId.value);
}

async function handleCopy() {
  const {
    item: { id: target_item_id },
    linkedApp: { id: linked_app_id },
  } = props;
  collectionStore.startBusy();
  try {
    const {
      data: { copies },
    } = await api.copyLinkedApp({
      source_item_id: selectedSourceItemId.value,
      linked_app_id,
      target_item_id,
    });
    emit('copied', copies);
  } catch (e) {
    console.log(e);
    captureException(e);
  } finally {
    collectionStore.stopBusy();
  }
}

watchEffect(() => {
  if (isLoadingSourceItems.value || isLoadingLinkedItemsCount.value) {
    collectionStore.startBusy();
  } else {
    collectionStore.stopBusy();
  }
});
</script>

<template>
  <div class="d-flex flex-column gap-2">
    <template v-if="isLoadingSourceItems || sourceItems.length > 0">
      <div class="form-group">
        <label class="form-label" :for="sourceItemSelectId">
          Select a source <span class="fw-bold">{{ app.title }}</span> item
        </label>

        <div class="input-group d-flex">
          <select
            class="form-control"
            :id="sourceItemSelectId"
            v-model="selectedSourceItemId"
          >
            <option :value="null">Not selected.</option>
            <option
              v-for="si of sourceItems"
              :value="si.id"
              :key="`linked-item-${si.id}`"
            >
              {{ si.custom_title }}
            </option>
          </select>

          <button
            v-if="selectedSourceItemId && sampleModal"
            class="btn btn-outline-primary"
            :disabled="sampleModal.hasChangedInputValues"
            @click.stop="gotoSourceItem"
          >
            <i class="fal fa-pencil-alt" />
          </button>
        </div>
      </div>

      <div v-if="selectedSourceItemId !== null" class="text-muted">
        {{ linkedItemsCount }}
        <span class="fw-bold">{{ linkedApp.title }}</span> items found
      </div>
      <button
        class="btn btn-outline-primary w-100"
        :disabled="
          collectionStore.isBusy ||
          !selectedSourceItemId ||
          linkedItemsCount === 0
        "
        @click="handleCopy"
      >
        <i class="fas fa-copy me-1" />Copy
      </button>
    </template>
    <AlertBox v-else type="info">
      There are no other <span class="fw-bold">{{ app.title }}</span> items for
      data copying.
    </AlertBox>
  </div>
</template>
