<template>
  <modal :show="true" @close="close" :loading="loading" :fixedHeight="true">
    <template v-slot:header> Health & Safety Categories </template>
    <div class="modal-body">
      <ul class="list-group">
        <template v-for="category in categories">
          <li
            class="list-group-item list-group-item-action"
            :key="`category-${category.name}`"
            :class="{
              active: isCategorySelected(category),
            }"
          >
            <input
              class="form-check-input me-1"
              type="checkbox"
              :id="`category-${category.name}`"
              :checked="isCategorySelected(category)"
              @change="selectCategory(category)"
            />
            <label
              class="form-check-label stretched-link"
              :for="`category-${category.name}`"
            >
              {{ category.name }}
            </label>
          </li>
          <div
            v-if="isCategorySelected(category)"
            :key="`category-${category.name}-hazards`"
          >
            <li
              class="list-group-item list-group-item-action hazard ms-3"
              v-for="hazard in category.hazards"
              :key="`category-${category.name}-${hazard.name}`"
            >
              <input
                class="form-check-input me-1"
                type="checkbox"
                :id="`category-${category.name}-${hazard.name}`"
                :checked="isHazardSelected(category, hazard)"
                @change="selectHazard(category, hazard)"
              />
              <label
                class="form-check-label stretched-link"
                :for="`category-${category.name}-${hazard.name}`"
              >
                {{ hazard.name }}
              </label>
            </li>
          </div>
        </template>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary w-100" @click="save">
        Select Categories
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/js/components/Modal.vue';
import Category from '@component-library/form/classes/Category.js';

export default {
  props: {
    tab: Object,
    updateTab: Function,
  },
  data: () => ({
    loading: false,
    categories: [],
    selectedCategories: [],
  }),
  components: {
    Modal,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.updateTab(this.selectedCategories);
      this.close();
    },
    isCategorySelected({ name }) {
      return (
        this.selectedCategories.findIndex((c) => c.category_name == name) != -1
      );
    },
    selectCategory(category) {
      if (this.isCategorySelected(category)) {
        this.selectedCategories.splice(
          this.selectedCategories.findIndex(
            (c) => c.category_name == category.name
          ),
          1
        );
      } else {
        this.selectedCategories.push({
          category_name: category.name,
          hazards: category.hazards.map((c) => c.name),
        });
      }
    },
    isHazardSelected(category, hazard) {
      return (
        this.selectedCategories.findIndex(
          (c) =>
            c.category_name == category.name &&
            c.hazards.indexOf(hazard.name) != -1
        ) != -1
      );
    },
    selectHazard(category, hazard) {
      let selectedCategory = this.selectedCategories.find(
        (c) => c.category_name == category.name
      );

      if (this.isHazardSelected(category, hazard)) {
        selectedCategory.hazards.splice(
          selectedCategory.hazards.indexOf(hazard.name),
          1
        );
      } else {
        selectedCategory.hazards.push(hazard.name);
      }
    },
  },
  async mounted() {
    this.loading = true;

    try {
      const data = await Category.get();

      this.categories = data;
      if (this.tab.hs_categories) {
        this.selectedCategories = this.tab.hs_categories;

        // any deleted account categories still need to appear if they've been selected in the past
        this.tab.hs_categories
          .filter(
            ({ category_name }) =>
              this.categories.findIndex((c) => c.name == category_name) == -1
          )
          .forEach((category) => {
            const newCategory = new Category({
              name: category.category_name,
              hazards: category.hazards.map((h) => {
                return {
                  name: h,
                };
              }),
            });

            this.categories.push(newCategory);
          });
      }
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style scoped>
ul > div {
  max-height: 200px;
  overflow: auto;
}

ul > div li:first-child {
  border-top: none;
}

ul > div li:last-child {
  border-bottom: none;
}

.hazard {
  width: calc(100% - 1rem);
}
</style>
