<script lang="ts" setup>
import Breadcrumb from '@/js/components/Breadcrumb.vue';
import EventBus from '@component-library/EventBus';
import { findPoiOwnerApps } from '@component-library/business-logic/app';
import { isCompanyManager } from '@component-library/company';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import AlertBox from '@component-library/components/AlertBox.vue';
import AppCardList from '@component-library/components/gather-app-management/AppCardList.vue';
import useApi from '@component-library/api';
import useAuth from '@component-library/composables/useAuth';
import useLegacyRootBus from '@component-library/composables/useLegacyRootBus';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { FieldTypeIds } from '@component-library/fields';
import type { AppLinkConfig, GatherField } from '@component-library/gather';
import { App } from '@component-library/gather';
import { Project } from '@component-library/project';
import { useFigureStore } from '@component-library/store/figures';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';
import { useProjectStore } from '@component-library/store/project';
import { useToastStore } from '@component-library/store/toasts';
import axios from 'axios';
import {
  computed,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import TemplateEditor from '../components/template/TemplateEditor.vue';
import TemplateLockedModal from '../components/template/TemplateLockedModal.vue';
import TemplateTabControls from '../components/template/TemplateTabControls.vue';
import TemplateTabEdit from '../components/template/TemplateTabEdit.vue';
import Spinner from '@component-library/components/Spinner.vue';

const api = useApi();
const { isViewOnly } = useViewRestriction(
  AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR
);
const { hasPermissionToAccess } = useViewRestriction();
const canEditApps = computed(() => {
  return hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR);
});
const projectStore = useProjectStore();
const toastStore = useToastStore();
const figureStore = useFigureStore();
const legacyRootBus = useLegacyRootBus();
const gatherSchema = useGatherSchemaStore();
const router = useRouter();
const route = router.currentRoute;
const auth = useAuth();
const projectId = parseInt(route.params.project_id);
const saving = ref(false);
const loadingTemplate = ref(false);
const selectedAppId = ref<number | null>(null);
const templateProject = ref<Project | null>(null);
const templateTabs = ref<App[]>([]);
const editingTab = ref(false);
const showLockedModal = ref(false);
const templateEditor = ref<typeof TemplateEditor>();

watch(
  () => route.params.project_id,
  (val) => {
    window.location.reload();
  }
);

const hasTemplateLock = ref(false);

const hasUnlockedProject = ref(
  localStorage.getItem('unlocked_editor') === String(projectId)
);

watch(selectedAppId, () => {
  if (
    hasTemplateLock.value &&
    !hasUnlockedProject.value &&
    !templateTabs.value.find((t) => t.id === selectedAppId.value)?.is_read_only
  ) {
    showLockedModal.value = true;
  }
});

const authUser = computed(() => {
  return auth.user()!;
});

const currentTab = computed(() => {
  return selectedAppId.value !== null
    ? templateTabs.value.find((t) => t.id === selectedAppId.value)
    : undefined;
});

const templateIsDisabled = computed(() => {
  return (
    showLockedModal.value ||
    (currentTab.value
      ? currentTab.value.is_read_only || isViewOnly() || !canEditApps.value
      : false) ||
    !canEditApps.value
  );
});

const isAdmin = computed(() => {
  return authUser.value?.role === 'admin' || isCompanyManager(authUser.value!);
});

const canShareTab = computed(() => {
  const userId = authUser.value?.user_id;

  if (!currentTab.value) {
    return true;
  }
  return (
    !currentTab.value.custom_template_id &&
    (isAdmin.value ||
      currentTab.value.template_tab_shareables?.length == 0 ||
      currentTab.value.template_tab_shareables?.some(
        (shareable) => shareable.shared_by_id == userId
      ))
  );
});

const isFirstTimeUsage = computed(() => {
  const sections = currentTab.value?.sections ?? [];

  if (sections.length > 1) {
    return false;
  }

  return !sections[0]?.template_fields?.length;
});

const otherTabs = computed(() => {
  return templateTabs.value.filter((tab) => tab.id !== currentTab.value?.id);
});

const appTitles = computed<string[]>(() => {
  return templateTabs.value
    .map((app) => app.title)
    .filter((v) => !!v) as string[];
});

function goMap() {
  router.push(`/${projectId}/map`);
}

function updateTemplate(value) {
  if (selectedAppId.value === null) {
    throw 'No selected tab index for app update';
  }
  const tabIndex = templateTabs.value.findIndex(
    (t) => t.id === selectedAppId.value
  );
  templateTabs.value[tabIndex] = value;
  saveTemplate();
}

function updateTemplateSections(sections) {
  if (!currentTab.value) {
    throw 'No current tab to update sections';
  }
  currentTab.value.sections = sections;
}

function selectAppById(id: number) {
  loadingTemplate.value = true;

  const tab = templateTabs.value.findIndex((t) => t.id === id);
  if (tab) {
    selectedAppId.value = id;
  } else {
    selectedAppId.value = templateTabs.value[0].id;
  }

  history.replaceState(
    null,
    '',
    `/#/template/${projectId}/editor/${selectedAppId.value}`
  );

  nextTick(() => {
    loadingTemplate.value = false;
  });
}

function editTab() {
  editingTab.value = true;
  legacyRootBus.$emit('clearSection');
}

async function removeTab() {
  const tabId = currentTab.value?.id;
  if (!tabId) {
    throw 'No tab ID for deletion';
  }

  // Forbid to delete the app if it is a POI app
  const poiOwnerApps = findPoiOwnerApps(templateTabs.value, tabId);
  if (poiOwnerApps.length > 0) {
    toastStore.info(
      `This app can not be deleted because it is used as a Point of Interest app of [${poiOwnerApps
        .map((app) => app.title)
        .join(', ')}] apps.`
    );
    return;
  }

  try {
    await axios.delete(`/api/template/tab/${tabId}`);
  } catch (e: any) {
    toastStore.error('Failed to delete tab, please refresh and try again');
    throw e.response;
  }

  templateTabs.value = templateTabs.value.filter((t) => t.id != tabId);
  selectAppById(templateTabs.value[0].id);
}

function parseTemplate(data) {
  templateProject.value = data.project;

  const previousSelectedTabId = currentTab.value?.id;
  templateTabs.value = data.template_tabs;
  if (previousSelectedTabId) {
    selectedAppId.value = previousSelectedTabId;
  }
}

async function loadTemplate() {
  loadingTemplate.value = true;
  const requestedTabId = parseInt(route.params.tab_id);

  try {
    await gatherSchema.getPhases(projectId);

    const response = await axios.get(
      `/api/template/${projectId}?project_id=${projectId}`
    );
    projectStore.updateProject(response.data.project);

    parseTemplate(response.data);

    if (templateTabs.value.length) {
      selectedAppId.value = requestedTabId || templateTabs.value[0].id;
    }

    hasTemplateLock.value = response.data.is_locked;

    if (
      hasTemplateLock.value &&
      !hasUnlockedProject.value &&
      !templateTabs.value.find((t) => t.id === selectedAppId.value)
        ?.is_read_only
    ) {
      showLockedModal.value = true;
    }

    // open rename tab input on initial load
    if (templateTabs.value.length == 0 || selectedAppId.value === null) {
      if (templateProject.value) {
        router.push(`/template/${projectId}/apps`);
      } else {
        throw 'No template project found';
      }
    } else if (
      isFirstTimeUsage.value &&
      !(currentTab.value?.is_read_only || isViewOnly())
    ) {
      await nextTick();
      editTab();
    }
  } catch (err) {
    throw err;
  } finally {
    loadingTemplate.value = false;
  }
}

async function updateAppOrdering(updatedPhase: Record<number, App[]>) {
  for (const phaseId in updatedPhase) {
    for (const [index, app] of updatedPhase[phaseId].entries()) {
      const appToUpdate = templateTabs.value.find((t) => t.id === app.id);
      if (appToUpdate) {
        appToUpdate.app_phase_group_id =
          phaseId === 'outliers' ? null : parseInt(phaseId);
        appToUpdate.order = index;
      }
    }
  }

  api.post(`/gather/phase/update-app-ordering`, {
    project_id: projectId,
    ordering: templateTabs.value.map((t) => {
      return {
        id: t.id,
        app_phase_group_id: t.app_phase_group_id,
        order: t.order,
      };
    }),
  });
}

function checkHasStaleReferenceFields() {
  return templateTabs.value.some((app) => {
    return app.sections?.some((s) => {
      return s.template_fields?.some((f) => {
        const referencedAppTitle = f.options?.template_tab_title ?? null;
        return (
          f.field_type_id === FieldTypeIds.REFERENCE &&
          referencedAppTitle !== null &&
          !appTitles.value.includes(referencedAppTitle)
        );
      });
    });
  });
}

async function saveTemplate() {
  if (templateIsDisabled.value || loadingTemplate.value || !currentTab.value) {
    return;
  }

  saving.value = true;
  try {
    await axios.post(`/api/template/${projectId}/update`, {
      template_tabs: templateTabs.value,
    });
    if (
      templateTabs.value.some((tab) => !tab.id) ||
      checkHasStaleReferenceFields()
    ) {
      await loadTemplate();
    }
  } catch (e) {
    toastStore.error(
      'Something went wrong while saving, refresh and try again.'
    );
    throw e;
  } finally {
    saving.value = false;
  }
}

async function updateTab({ key, value, onFinished = () => {} }) {
  updateTabInBulk({ update: { [key]: value }, onFinished });
}

async function updateTabInBulk({ update, onFinished = () => {} }) {
  const tab = currentTab.value;
  if (!tab) {
    throw 'No tab to update in bulk';
  }
  for (const key in update) {
    const value = update[key];
    if (key !== 'linked_app_link_config') {
      tab[key] = value;
    } else {
      const { linkedAppId, linkConfig } = value;
      const linkedApp = templateTabs.value.find(
        (app) => app.id === linkedAppId
      );
      if (linkedApp) {
        const linkConfigs = linkedApp.link_configs ?? [];
        const index =
          linkConfigs.findIndex(
            (lc) => lc.linkFieldId === linkConfig.linkFieldId
          ) ?? -1;
        if (index === -1) {
          linkConfigs.push(linkConfig);
        } else {
          linkConfigs.splice(index, 1, linkConfig);
        }
        linkedApp.link_configs = linkConfigs;
      }
    }
  }

  try {
    await saveTemplate();
  } finally {
    onFinished();
  }
}

function unlockTemplate() {
  localStorage.setItem('unlocked_editor', String(projectId));
  hasUnlockedProject.value = true;
  showLockedModal.value = false;
}

function finishEditingTab() {
  editingTab.value = false;
}

function handleAddSection({ element, index }) {
  templateEditor.value?.addSection({ element, index });
}

function handleRemoveSection(index) {
  templateEditor.value?.removeSection(index);
}

function handleUpdateFieldOptions({ sectionIndex, fieldIndex, options }) {
  templateEditor.value?.updateFieldOptions({
    sectionIndex,
    fieldIndex,
    options,
  });
}

function updateLinkConfigs(
  field: GatherField,
  app: App,
  checkIsLinkConfigsOutdated: (
    linkConfigs: AppLinkConfig[],
    linkedApp: App
  ) => boolean
) {
  if (field.field_type_id !== FieldTypeIds.REFERENCE) {
    return;
  }

  const otherApps = templateTabs.value.filter((_app) => _app.id !== app.id);
  for (const oa of otherApps) {
    const linkConfigs = oa.link_configs ?? [];
    const isOutdated = checkIsLinkConfigsOutdated(linkConfigs, oa);
    if (isOutdated) {
      const newLinkConfigs = linkConfigs.filter(
        (lc) => lc.linkFieldId !== field.id
      );
      oa.link_configs = newLinkConfigs.length > 0 ? newLinkConfigs : null;
    }
  }
}

function onFieldUpdated(field: GatherField, app: App) {
  updateLinkConfigs(field, app, (linkConfigs, linkedApp) => {
    return linkConfigs.some(
      (lc) =>
        lc.linkFieldId === field.id &&
        field.options?.template_tab_title !== linkedApp.title
    );
  });
}

function onFieldDeleted(field: GatherField, app: App) {
  updateLinkConfigs(field, app, (linkConfigs) => {
    return linkConfigs.some((lc) => lc.linkFieldId === field.id);
  });
}

onBeforeMount(async () => {
  await loadTemplate();

  loadingTemplate.value = true;
  try {
    await figureStore.fetchProjectFigures(templateProject.value!.project_id);
  } finally {
    loadingTemplate.value = false;
  }

  EventBus.$on('reloadTemplate', loadTemplate);
  legacyRootBus.$on('closeTemplateOptions', finishEditingTab);
});

onBeforeUnmount(() => {
  EventBus.$off('reloadTemplate');
  legacyRootBus.$off('closeTemplateOptions', finishEditingTab);
});
</script>

<template>
  <div
    class="template-page-content col-12 col-md-10 offset-0 offset-md-1 py-4 px-3 px-md-0 h-100 d-flex flex-column"
  >
    <div
      v-if="loadingTemplate"
      class="d-flex align-items-center justify-content-center flex-column text-center w-100 h-100"
    >
      <Spinner large />
      <small class="text-muted mt-2 d-block"> Loading apps... </small>
    </div>
    <template v-else>
      <Breadcrumb :routes="['template_editor']" class="mb-4">
        <Spinner small v-if="saving" margin="me-2 ms-2" />
      </Breadcrumb>

      <div class="row mb-lg-3">
        <div class="col-12 mb-2 mb-sm-0">
          <AppCardList
            v-if="selectedAppId !== null"
            :selectedAppId="selectedAppId"
            :apps="templateTabs"
            :phases="gatherSchema.phases"
            :collapsedPhaseIds="gatherSchema.collapsedPhaseIds"
            @selectAppById="selectAppById"
            @editTab="editTab"
            @removeTab="removeTab"
            @reloadTemplate="loadTemplate"
            :canCreateApp="!isViewOnly()"
            @updateAppOrdering="updateAppOrdering"
            @updateCollapsedPhases="
              ($event) => (gatherSchema.collapsedPhaseIds = $event)
            "
            @updatePhase="
              ({ id, data }) =>
                gatherSchema.updatePhase(
                  id,
                  { [data.key]: data.value },
                  projectId
                )
            "
            @updatePhaseOrdering="
              ($event) => gatherSchema.updatePhaseOrdering($event, projectId)
            "
            :isDisabled="!!templateIsDisabled"
            :projectId="projectId"
          />
        </div>
      </div>

      <AlertBox v-if="templateIsDisabled" type="warning">
        You only have view access to this template.
      </AlertBox>

      <template v-if="currentTab?.id">
        <TemplateEditor
          ref="templateEditor"
          :disabled="templateIsDisabled"
          :project="templateProject"
          :tab="currentTab"
          :tabs="templateTabs"
          :onFieldUpdated="onFieldUpdated"
          :onFieldDeleted="onFieldDeleted"
          @input="updateTemplate"
          @goMap="goMap"
          @finishEditingTab="finishEditingTab"
          @updateTemplateSections="updateTemplateSections"
          @updateTab="updateTab"
        >
          <template #template-tab-editor>
            <TemplateTabControls
              v-if="currentTab.id && !templateIsDisabled"
              :templateTabs="templateTabs"
              :templateTab="currentTab"
              :canShare="!!canShareTab"
              :isAdmin="isAdmin"
              class="mb-2"
            />

            <TemplateTabEdit
              v-if="editingTab"
              :tab="currentTab"
              :otherTabs="otherTabs"
              @updateTab="updateTab"
              @updateTabInBulk="updateTabInBulk"
              @finishEditing="finishEditingTab"
              @add-section="handleAddSection"
              @remove-section="handleRemoveSection"
              @update-field-options="handleUpdateFieldOptions"
            />
          </template>
        </TemplateEditor>

        <TemplateLockedModal
          v-if="templateProject && showLockedModal"
          :project="templateProject"
          @close="() => (showLockedModal = false)"
          @unlock="unlockTemplate"
        />
      </template>
    </template>
  </div>
</template>
