<template>
  <div>
    <ApplicationNavigation
      :isAuthenticated="!!authUser"
      :isStagingEnvironment="
        !navigationStore.demoModeEnabled && !navigationStore.isProduction
      "
      moduleName="gather"
      :logo="logo"
      class="gather"
    >
      <template #appSwitcher>
        <AppSwitcherDropdown
          v-if="isOnline && authUser"
          @loadProjectData="loadProjectData"
          :rightSide="true"
          class="me-3"
        />

        <div v-if="!isOnline" class="badge bg-danger ms-2 d-block">Offline</div>
      </template>
      <template #mobileMenu>
        <ProgressBar
          v-if="!isOnline && quota"
          :progress="offlineStorageUsed"
          title="storage"
          :message="formatQuota(quota)"
        />

        <DropdownNavItem v-if="project && isOnline" :hasPadding="true">
          <template #triggerBtn>
            <i class="fal fa-fw fa-map-marked-alt"></i>
            <span>Project</span>
          </template>
          <template #dropdownItems>
            <div
              class="dropdown-item"
              @click="goToRouteViaDropdown(`/${project.project_id}/map`)"
              @mousedown.middle="
                goToRouteViaDropdown(`/${project.project_id}/map`, true)
              "
            >
              <i class="fal fa-map-marker-plus fa-fw" /> Gather Data
            </div>
            <div
              class="dropdown-item"
              :class="{
                active: isRouteActive('/editor'),
              }"
              @click="
                goToRouteViaDropdown(`/template/${project.project_id}/editor`)
              "
              @mousedown.middle="
                goToRouteViaDropdown(
                  `/template/${project.project_id}/editor`,
                  true
                )
              "
            >
              <i class="fal fa-tasks-alt fa-fw" />
              App Editor
            </div>
            <div
              v-if="hasCoc"
              class="dropdown-item"
              @click="
                goToRouteViaDropdown(`/${project.project_id}/chain-of-custody`)
              "
              @mousedown.middle="
                goToRouteViaDropdown(
                  `/${project.project_id}/chain-of-custody`,
                  true
                )
              "
            >
              <i class="fal fa-vial fa-fw"></i>
              <span>CoC</span>
            </div>
            <div class="dropdown-item" @click="gotoProjectSettings">
              <i class="fal fa-cog fa-fw" />
              Settings
            </div>
            <template v-if="isRouteActive('/map')">
              <div
                class="dropdown-item"
                :class="{
                  active: isMapView,
                }"
                @click="$root.$emit('changeViewType', false)"
              >
                <i class="fal fa-fw fa-map" /> Map View
              </div>
              <div
                class="dropdown-item"
                :class="{
                  active: isNonSpatialView,
                }"
                @click="$root.$emit('changeViewType', true)"
              >
                <i class="fal fa-fw fa-bars" /> Non-spatial View
              </div>
            </template>

            <div
              v-if="authUser.role === 'admin'"
              class="dropdown-item"
              @click="showAdminUploadModal = true"
            >
              <i class="fal fa-fw fa-download" /> Admin Upload
            </div>
          </template>
        </DropdownNavItem>

        <DropdownNavItem v-if="isOnline" :hasPadding="true">
          <template #triggerBtn>
            <i class="fal fa-user me-0"></i>
          </template>
          <template #dropdownItems>
            <div class="dropdown-item" @click="gotoAccount">
              <i class="fal fa-user-alt fa-fw"></i>
              Account
            </div>

            <div class="dropdown-item" @click="openHelpCenter">
              <i class="fal fa-user-shield fa-fw"></i>
              <span>Help Center</span>
            </div>

            <div v-if="hasLiveChat" class="dropdown-item" @click="openLiveChat">
              <i class="fal fa-comment-dots fa-fw"></i>
              <span>Talk to us?</span>
            </div>

            <button
              v-if="isMobile"
              class="dropdown-item"
              @click="showOfflineSetupModal = true"
            >
              <i class="fal fa-bell fa-fw"></i>Manage Offline Settings
            </button>

            <div class="dropdown-item" @click="logoutSession">
              <i class="fal fa-sign-out fa-fw"></i>
              <span>Sign Out</span>
            </div>
          </template>
        </DropdownNavItem>

        <DropdownNavItem
          v-if="
            offlineStorageManagerStore &&
            offlineStorageManagerStore.offlineProjects.length > 0
          "
          :isActive="false"
          :hasPadding="true"
          class="d-lg-none"
        >
          <template #triggerBtn>
            <i class="fal fa-fw fa-cloud-upload-alt"></i>
          </template>
          <template #dropdownItems>
            <div
              v-for="offlineProject in offlineStorageManagerStore.offlineProjects"
              :key="`offline-project-${offlineProject.project_id}`"
              class="dropdown-item py-3 d-flex align-items-center"
              @click="openProject(offlineProject)"
            >
              <i
                class="fa-map"
                :class="[
                  project && offlineProject.project_id === project.project_id
                    ? 'fas'
                    : 'fal',
                ]"
              />
              <div class="d-flex flex-column">
                <span class="d-block fw-medium overflow-hidden mb-0 text-wrap">
                  {{
                    offlineProject.project_number +
                    (offlineProject.project_name
                      ? ' - ' + offlineProject.project_name
                      : '')
                  }}
                </span>
                <small>{{ offlineProject.project_client }}</small>
              </div>
            </div>
          </template>
        </DropdownNavItem>

        <DropdownNavItem
          v-if="
            isOnline &&
            project?.synced_offline_requests &&
            project.synced_offline_requests > 0 &&
            !offlineStorageManagerStore.isDownloading
          "
          :isActive="false"
          :hasPadding="true"
        >
          <template #triggerBtn>
            <i class="fal fa-fw fa-cloud-upload-alt"></i>
          </template>
          <template #dropdownItems>
            <div
              class="dropdown-item"
              @click.prevent.stop="navigationStore.showGatherUploadModal = true"
            >
              Upload Offline Data
            </div>
          </template>
        </DropdownNavItem>
      </template>
      <template #rightOptions>
        <div class="d-flex">
          <a
            v-if="offlineStorageManagerStore.isDownloading"
            class="btn btn-header"
            href="#"
          >
            <Spinner small />
          </a>
        </div>
      </template>
    </ApplicationNavigation>

    <div
      style="
        position: absolute;
        bottom: 15px;
        z-index: 9999;
        left: 0;
        right: 0;
        width: 350px;
        margin: 0 auto;
        overflow: hidden;
      "
    >
      <transition name="dropdown">
        <div
          class="p-3 text-center"
          style="
            box-shadow: rgb(0 0 0 / 10%) 0px 3px 4px 0px,
              rgb(0 0 0 / 5%) 0px 3px 3px -2px, rgb(0 0 0 / 3%) 0px 1px 8px 0px;
            background: #ffffff;
          "
          v-show="showSwUpdated || networkIssue"
        >
          <h6 class="mb-3">
            <template v-if="networkIssue">
              Your network status has changed, please refresh to get the latest
              information
            </template>
            <template v-else>
              Datanest has been updated, please refresh to get the latest
              version
            </template>
          </h6>
          <button
            type="button"
            class="btn btn-primary w-100"
            @click="reloadWindow"
          >
            Refresh
          </button>
        </div>
      </transition>
    </div>

    <OfflineUploadModal
      v-if="navigationStore.showGatherUploadModal"
      @close="navigationStore.showGatherUploadModal = false"
    />

    <AdminOfflineUploadModal
      v-if="showAdminUploadModal"
      @close="showAdminUploadModal = false"
    />

    <OfflineSetupModal
      v-if="showOfflineSetupModal"
      @close="showOfflineSetupModal = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppSwitcherDropdown from '@component-library/switcher/AppDropdown.vue';
import ProgressBar from './components/ProgressBar.vue';
import { loadProjectDetails } from '../project/list/api';
import cocApi from '../project/chain-of-custody/api/index.js';
import useChatProvider from '@component-library/chat-service';
import EventBus from '@component-library/EventBus';
import { mapStores } from 'pinia';
import { useNavigationStore } from '@component-library/store/navigation';
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import { useToastStore } from '@component-library/store/toasts';
import OfflineUploadModal from '@/js/components/offline-upload/Modal.vue';
import ApplicationNavigation from '@component-library/navigation/index.vue';
import DropdownNavItem from '@component-library/navigation/DropdownNavItem.vue';
import { useProjectStore } from '@component-library/store/project';
import DropdownMenu from '@component-library/components/DropdownMenu.vue';
import { DATANEST_URL } from '@component-library/env';
import OfflineSetupModal from './components/OfflineSetupModal.vue';
import AdminOfflineUploadModal from '@/js/components/offline-admin/UploadModal.vue';
import { checkIsMobile, getDeviceName } from '@component-library/utils';
import localForage from 'localforage';
import useRequestOfflineSetup from '@/js/composables/useRequestOfflineSetup';
import auth from '@component-library/auth';
import Spinner from '@component-library/components/Spinner.vue';
import { goToApp } from '@component-library/switcher/handover';
import useProjectId from '@component-library/composables/useProjectId';

const chatProvider = useChatProvider();

export default {
  data: () => ({
    showProjectDropdown: false,
    showAccountDropdown: false,
    offlineStorageUsed: null,
    showSwUpdated: false,
    isNetworkOnline: true,
    quota: null,
    hasCoc: false,
    hasOfflineUpload: false,
    showAdminUploadModal: false,
    showOfflineSetupModal: false,
  }),
  components: {
    ApplicationNavigation,
    DropdownNavItem,
    AppSwitcherDropdown,
    ProgressBar,
    DropdownMenu,
    OfflineUploadModal,
    OfflineSetupModal,
    AdminOfflineUploadModal,
    Spinner,
  },
  watch: {
    isOnline(updated) {
      if (!updated) this.hasCoc = false;
    },
    project() {
      if (this.hasCoc) {
        return;
      }
      this.updateHasCoc();
    },
  },
  computed: {
    ...mapGetters({
      get_project_field_by_key: 'get_project_field_by_key',
    }),
    ...mapState({
      project: (state) => state.project,
      isOnline: (state) => state.isOnline,
    }),
    authUser() {
      return auth.user();
    },
    hasLiveChat: chatProvider.hasLiveChat,
    logo() {
      return '/images/datanest-logo-title.svg';
    },
    networkIssue() {
      return this.isNetworkOnline != this.isOnline;
    },
    env: () => {
      return import.meta.env.VITE_APP_ENV;
    },
    isMobile() {
      return checkIsMobile() || auth.user().role === 'admin';
    },
    isMapView() {
      return (
        this.$route.path.endsWith('/map') &&
        !this.project.is_gather_non_spatial_view
      );
    },
    isNonSpatialView() {
      return (
        this.$route.path.endsWith('/map') &&
        this.project.is_gather_non_spatial_view
      );
    },
    ...mapStores(useNavigationStore, useOfflineStorageManagerStore),
  },
  methods: {
    async checkQuota() {
      if (navigator.storage && navigator.storage.estimate) {
        this.quota = await navigator.storage.estimate();
        const percentageUsed = (this.quota.usage / this.quota.quota) * 100;

        this.offlineStorageUsed = percentageUsed;
      }
    },
    openLiveChat() {
      if (auth.impersonating()) {
        const toastStore = useToastStore();
        toastStore.error('Cannot open chat while impersonating');
        throw new Error('Cannot open chat while impersonating');
      }
      let user = auth.user();
      let projectId = this.project?.project_id;
      chatProvider.open(user, projectId);
      this.showAccountDropdown = false;
    },
    openHelpCenter() {
      window.open(DATANEST_URL + '/support', '_blank');
    },
    gotoAccount() {
      EventBus.$emit('goToApp', {
        app: 'hub',
        route: 'account',
      });
    },
    gotoProjectSettings() {
      EventBus.$emit('goToApp', {
        app: 'hub',
        route: 'project_settings',
      });
    },
    logoutSession() {
      auth.logout().then(() => {
        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
        }

        auth.clear();
        goToApp({
          $router: this.$router,
          app: 'hub',
          route: 'login',
        });
      });
    },
    loadProjectData() {
      loadProjectDetails(this.project.project_id).then((response) => {
        const projectStore = useProjectStore();
        projectStore.updateProject(response.data.project);
      });
    },
    isRouteActive(path) {
      return this.$route.path.includes(path);
    },
    goToRouteViaDropdown(route, newTab = false) {
      // If already on 'map' page toggle non spatial.
      if (route.endsWith('/map') && this.$route.path.endsWith('/map')) {
        this.$root.$emit(
          'changeViewType',
          !this.project.is_gather_non_spatial_view
        );
        return;
      }
      this.showProjectDropdown = false;
      this.showAccountDropdown = false;

      if (newTab) {
        window.open('/#' + route, '_blank');
        return;
      }

      this.$router.push(route, () => {});
    },
    toggleProjectDropdown() {
      this.showAccountDropdown = false;
      this.showProjectDropdown = !this.showProjectDropdown;
    },
    toggleAccountDropdown() {
      this.showProjectDropdown = false;
      this.showAccountDropdown = !this.showAccountDropdown;
    },
    updateOnlineStatus() {
      this.isNetworkOnline = navigator.onLine;
    },
    reloadWindow() {
      window.location.reload();
    },
    updateHasCoc() {
      if (!this.isOnline || !this.project?.project_id) return;

      cocApi.getHasCoc().then(({ data }) => {
        this.hasCoc = data.has_coc;
      });
    },
    openProject(project) {
      window.location.href = `/#/${project.project_id}/map`;
      window.location.reload();
    },
    formatQuota(quota) {
      const usageMB = parseInt(quota.usage / 1048576);
      const totalMB = parseInt(quota.quota / 1048576);

      if (totalMB < 1024) {
        return `${usageMB.toFixed(2)} MB/${totalMB.toFixed(2)} MB`;
      } else {
        const usageGB = usageMB / 1024;
        const totalGB = totalMB / 1024;
        return `${usageGB.toFixed(2)} GB/${totalGB.toFixed(2)} GB`;
      }
    },
  },
  created() {
    document.addEventListener(
      'swUpdated',
      () => {
        this.showSwUpdated = true;
      },
      { once: true }
    );
  },
  async mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    this.$store.dispatch('updateNetworkState', navigator.onLine);

    this.updateOnlineStatus();
    this.checkQuota();

    if (this.isOnline && this.project) {
      if (this.offlineStorageManagerStore.offlineProjects.length > 0) {
        this.hasOfflineUpload = true;
        if (
          this.offlineStorageManagerStore.offlineProjects.filter(
            (p) =>
              p.samples.filter(
                (s) => s.is_created_offline || s.is_edited_offline
              ).length > 0
          ).length > 0
        ) {
          this.navigationStore.showGatherUploadModal = true;
        }
      }

      const hasAcknowledgedOfflineSetup =
        localStorage.getItem('hasAcknowledgedOfflineSetup') === 'true';

      if (
        !this.navigationStore.showGatherUploadModal &&
        !hasAcknowledgedOfflineSetup &&
        this.isMobile
      ) {
        this.showOfflineSetupModal = true;
      }

      if (
        hasAcknowledgedOfflineSetup &&
        this.isMobile &&
        !this.isRouteActive('handover')
      ) {
        await localForage.setItem('device', getDeviceName());

        if (!auth.user().company.is_force_local_gather_offline_mode) {
          const { subscribeToNotifications } = useRequestOfflineSetup();
          subscribeToNotifications();
        }
      }
    }

    this.updateHasCoc();
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>
